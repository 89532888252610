/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
/*
@import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Muli:400,700');
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800");
@import "~@ng-select/ng-select/themes/material.theme.css";

@import "~@ng-select/ng-select/themes/material.theme.css";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
    $font-family: "Montserrat",
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(56px, 56px, 400),
    $display-2: mat.define-typography-level(45px, 48px, 400),
    $display-1: mat.define-typography-level(34px, 40px, 400),
    $headline: mat.define-typography-level(24px, 32px, 400),
    $title: mat.define-typography-level(20px, 32px, 500),
    $subheading-2: mat.define-typography-level(16px, 28px, 400),
    $subheading-1: mat.define-typography-level(15px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level(14px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
    $input: mat.define-typography-level(inherit, 1.125, 400),
);

@include mat.typography-hierarchy($custom-typography);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($custom-typography);

@include mat.core($custom-typography);

body {
    margin: 0;
    font-family: "Montserrat", sans-serif !important;
}

html,
body {
    height: 100%;
}
iframe {
    border: none;
}

/*
 *	Utility classes -------------------------------------------->
 */
.pointer {
    cursor: pointer;
}

.cursor-grab {
    cursor: grab;
}
.cursor-crosshair {
    cursor: crosshair;
}
.cursor-move {
    cursor: move;
}
.cursor-not-allowed {
    cursor: not-allowed!important;
}
.cursor-auto {
    cursor: auto!important;
}
.p-relative {
    position: relative;
}

.pleft-25 {
    padding-left: 25px;
}

.pleft-20 {
    padding-left: 20px;
}

.pleft-40 {
    padding-left: 40px;
}

.p-top15 {
    padding-top: 15px;
}

.p-0 {
    padding: 0px;
}

.p-20 {
    padding: 20px;
}

.m-0 {
    margin: 0px;
}

.mright-4 {
    margin-right: 4px;
}

.mleft-4 {
    margin-left: 4px;
}

.width-34 {
    width: 34px;
}

.width-43 {
    width: 43px;
}

.width-100 {
    width: 100%;
}

.width-percent-89 {
    width: 89%;
}

.height-inherit {
    height: inherit;
}

.height-20 {
    height: 20px;
}

.height-40 {
    height: 40px!important;
}

.height-43 {
    height: 43px;
}

.height-45 {
    height: 45px;
}

.height-52 {
    height: 52px;
}

.height-64 {
    height: 64px;
}

.height-85 {
    height: 85px;
}

.cursor-pointer {
    cursor: pointer!important;
}

.color-orange {
    color: #f58220 !important;
}

.color-transparent {
    color: transparent !important;
}

.color-blue {
    color: #274397 !important;
}

.color-blue-primary {
    color: #004b7d !important;
}

.color-blue-secondary {
    color: #3b5297 !important;
}

.color-white {
    color: #fff;
}

.color-black {
    color: #000000 !important;
}

.color-grey {
    color: #b4b8c8 !important;
}

.color-dark-grey {
    color: #5a6478 !important;
}

.color-action-grey {
    color: #8c96aa !important;
}

.color-red-primary {
    color: #ff194b!important;
}

.color-green-primary {
    color: #00c87d!important;
}

.bgcolor-blue {
    background-color: #274397 !important;
}

.bgcolor-red-primary {
    background-color: #ff194b;
}

.bgcolor-white {
    background-color: #ffffff;
}

.bgcolor-orange {
    background-color: #ffaf32;
}

.bgcolor-orange-primary {
    background-color: #f58220 !important;
}

.bgcolor-grey {
    background-color: #fafafa !important;
}

.bgcolor-light-grey {
    background-color: #eceef0 !important;
}

.bgcolor-lightblue {
    background-color: #809fff !important;
}

.bgcolor-whitesmoke {
    background-color: #f4f4f4 !important;
}

.bgcolor-spindle {
    background-color: #b4bec8 !important;
}

.utility-green-bg-color {
    background-color: #00c87d !important;
}

.utility-blue-bg-color {
    background-color: #4169e1 !important;
}

.utility-orange-bg-color {
    background-color: #ffaf32 !important;
}

.utility-red-bg-color {
    background-color: #ff194b !important;
}

.utility-info-bg-color {
    background-color: #54b4d3 !important;
}

.utility-grey-bg-color {
    background-color: #8c96aa !important;
}

.utility-grey-dark-bg-color {
    background-color: #d2d6df !important;
}

.utility-black-bg-color {
    background-color: #000000 !important;
}

.utility-bg-error {
    background-color: #ff194b;
}

.border-error {
    border: 1px solid #ff194b !important;
}

.border-orange {
    border: 1px solid #f58220;
}

.border-grey {
    border: 1px solid #8c96aa;
}

.border-whitesmoke {
    border: 1px solid #f4f4f4;
}

.border-right-grey {
    border-right: 1px solid #8c96aa;
}

.border-right-light-grey {
    border-right: 1px solid #eceef0 !important;
}

.border-left-grey {
    border-left: 1px solid #8c96aa;
}

.border-left-light-grey {
    border-left: 1px solid #eceef0 !important;
}

.border-top-grey {
    border-top: 1px solid #8c96aa;
}

.border-bottom-grey {
    border-bottom: 1px solid #eceef0;
}

.border-grey-1 {
    border: 1px solid #b4b8c8;
}

.border-top-grey-1 {
    border-top: 1px solid #b4b8c8;
}

.border-bottom-grey-1 {
    border-bottom: 1px solid #b4b8c8;
}

.border-top-light-grey {
    border-top: 1px solid #eceef0 !important;
}
.double-border-top-blue {
    border-top: 2px solid #274397 !important;
}
.double-border-left-blue {
    border-left: 2px solid #274397 !important;
}
.height-34 {
    height: 34px;
}

.font-family-montserrat {
    font-family: "Montserrat" !important;
}

.font-family-open-sans {
    font-family: "Open Sans" !important;
}

.font-size-7 {
    font-size: 7px !important;
}

.font-size-8 {
    font-size: 8px !important;
}

.font-size-9 {
    font-size: 9px !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-19 {
    font-size: 19px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}
.font-size-23 {
    font-size: 23px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-26 {
    font-size: 26px !important;
}

.font-size-27 {
    font-size: 27px !important;
}

.font-size-28 {
    font-size: 28px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-34 {
    font-size: 34px !important;
}

.font-size-36 {
    font-size: 36px !important;
}

.font-size-37 {
    font-size: 37px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-42 {
    font-size: 42px !important;
}

.font-size-50 {
    font-size: 50px !important;
}

.font-size-64 {
    font-size: 64px !important;
}

.font-size-85 {
    font-size: 85px !important;
}

.font-size-90 {
    font-size: 90px !important;
}

.font-size-200 {
    font-size: 200px !important;
}

.font-size-225 {
    font-size: 225px !important;
}

.user-select-none {
    user-select: none;
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-400 {
    font-weight: 400;
}
.font-weight-500 {
    font-weight: 500;
}

.textalign-right {
    text-align: right;
}
.text-overflow-ellipsis {
    text-overflow: ellipsis;
}

.vertical-align-super {
    vertical-align: super;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.vertical-align-text-bottom {
    vertical-align: text-bottom;
}

.vertical-align-sub {
    vertical-align: sub;
}

.text-select-none {
    user-select: none;
}

.z-index-100 {
    z-index: 100;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.display-flex {
    display: flex;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.flex-align-self-center {
    align-self: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.overflow-unset {
    overflow: unset !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-y-hidden {
    overflow-y: hidden;
}
.width-180px {
    width: 180px;
}
.min-width-100px {
    min-width: 100px;
}

.max-width-80px {
    max-width: 80px;
}

.max-width-125px {
    max-width: 125px;
}

.max-width-320px {
    max-width: 320px;
}

.max-width-350px {
    max-width: 350px;
}

.max-width-600px {
    max-width: 600px;
}

.max-width-100 {
    max-width: 100% !important;
}
.spinner {
    height: 100%;
    top: 0;
    width: calc(100% - 100px);

    mat-spinner {
        top: calc(50% - 50px);
        z-index: 1000;
    }
}
.user-no-select {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}
/*
 *	Utility classes -------------------------------------------->
 */
/*
 *	Components styling -------------------------------------------->
 */
.navbar {
    box-shadow: none !important;
    border-bottom: 1px solid #eceef0;
}

*:focus {
    outline: none;
}

.overview-scroll {
    height: 100%;
    overflow: hidden;
}

.boxshadow {
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.contentcontainer {
    height: calc(100% - 64px);
    padding: 0 0px 0 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

/*
 *	Button
 */
.button {
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    user-select: none;
}

.button[disabled] {
    cursor: auto;
    background-color: #e0e3e6 !important;
}

.btn {
    border-radius: 4px;
    &-flat {
        border: none;
    }
    [disabled] {
        cursor: auto;
        color: #fff;
        background-color: #eceef0 !important;
    }
    &:hover,
    &:focus {
        color: #ffffff;
    }
}

.btn-blue {
    background-color: #274397;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    border-radius: 4px;
    &:hover,
    &:focus {
        background-color: #274397;
        color: #ffffff;
    }
    &:disabled {
        background-color: #8c96a0;
    }
}

.mat-button {
    &:disabled {
        background-color: #8c96a0;
    }
}

.btn-transparent {
    background-color: transparent;
    color: #8c96a0 !important;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    &:hover,
    &:focus {
        background-color: transparent;
        color: #8c96a0 !important;
    }
}
.button-component.disabled {
    background-color: #eceef0;
}

.button-component:hover {
    cursor: pointer;
}

.button-component.disabled:hover {
    cursor: auto;
}

.button-component:hover {
    .inner {
        border-bottom: 1px solid #ffffff;
    }

    .inner-disabled {
        border-bottom: 1px solid #eceef0;
    }
}
.button-component {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 2px;
    height: fit-content;

    .inner-green {
        border-bottom: 1px solid #00c87d;
    }

    .disabled {
        background-color: #eceef0;
    }

    .inner-disabled {
        border-bottom: 1px solid #eceef0;
    }

    .inner-red {
        border-bottom: 1px solid #ff194b;
    }

    .inner-blue {
        border-bottom: 1px solid #274397;
    }

    .inner-white {
        border-bottom: 1px solid #ffffff;
    }

    .inner-grey {
        border-bottom: 1px solid #8c96aa;
    }

    .inner-orrange {
        border-bottom: 1px solid #f58220;
    }

    .inner-info {
        border-bottom: 1px solid #54b4d3;
    }

    .inner {
        span {
            font-family: "Open Sans";
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            margin-left: 10px;
        }
    }

    .inner.export-button {
        padding: 9px 13px;
    }
}
.export-icon {
    color: #fff;
    font-size: 1.3rem;
}
//ebutton class
.eButton {
    border: none;
    background-color: #ffffff;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    border-radius: 2px;
    user-select: none;
    word-break: break-word;
}

.eButton.cancel {
    color: #8c96a0;
}
.eButton.cmr {
    color: #00c87d;
    border: 1px solid #00c87d;
}
.eButton.primary {
    color: #ffffff;
    background-color: #00c87d;
}
.eButton.orange {
    color: #ffffff;
    background-color: #f58220;
}
.eButton.error {
    color: #ffffff;
    background-color: #ff194b;
}
.eButton.secondary {
    color: #ffffff;
    background-color: #8c96a0;
}
.eButton.tertiary {
    color: #ffffff;
    background-color: #274397;
}
.eButton.disable {
    color: #8c96a0;
    border: 1px solid #8c96a0;
}

.mat-dialog-content,
.mat-dialog-container {
    overflow: visible !important;
}
/*
 *	Button
 */
/*
 *	Mat tabs
 */
mat-tab-group {
    mat-tab-header {
        border: none !important;
        margin-bottom: 1rem;

        .mat-tab-list {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .mat-tab-label {
                border-left: 1px solid rgba(0, 0, 0, 0.12);
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                margin-top: 5px;
                height: 36px;
                min-width: unset !important;
                background-color: #fafafa;
                font-family: "Open Sans" !important;
                font-size: 13px !important;
                user-select: none;

                .mat-tab-label-content {
                    display: flex;
                    align-items: center;
                }
            }
            .mat-tab-label.mat-tab-label-active {
                background-color: #ffffff !important;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
                padding-top: 5px;
                margin-top: 0;
                color: #004b7d;
                height: 42px;
            }
            .mat-tab-label-active.mat-tab-label {
                border-bottom: none !important;
            }
            .mat-tab-label:last-child {
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }
            mat-ink-bar {
                background-color: #ffffff !important;
                height: 1px;
                bottom: -1px;
            }
        }
    }

    /*
    *   Second tab
    */
    .mat-tab-body-wrapper {
        height: 100% !important;

        mat-tab-body {
            mat-tab-header {
                margin-left: 5px !important;
                margin-right: 5px !important;
            }
            .mat-tab-body-content {
                margin-left: 5px !important;
                margin-right: 5px !important;

                .mat-tab-labels {
                    justify-content: flex-end;
                }
            }
        }
    }

    &.cargobox-invoice {
        .mat-tab-labels {
            justify-content: flex-start!important;
        }
    }
}

/*
 *	Mat tabs
*/
/*
/*
 *	Mat navbar
*/
.mat-tab-nav-bar.mat-tab-header {
    border: none !important;

    .mat-tab-list {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .mat-tab-link {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            margin-top: 5px;
            height: 36px;
            min-width: unset !important;
            background-color: #fafafa;
            font-family: "Open Sans" !important;
            font-size: 13px !important;
            user-select: none;
            text-decoration: none!important;


            a {
                display: flex;
                align-items: center;
            }
        }
        .mat-tab-link.mat-tab-label-active {
            background-color: #ffffff !important;
            border-right: 1px solid rgba(0, 0, 0, 0.12);
            padding-top: 5px;
            margin-top: 0;
            color: #004b7d;
        }
        .mat-tab-label-active.mat-tab-label {
            border-bottom: none !important;
        }
        .mat-tab-link:last-child {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
        mat-ink-bar {
            background-color: #ffffff !important;
            height: 1px;
            bottom: -1px;
        }
    }
}
/*
/*
 *	Mat navbar
*/
/*
 *	Scrollbar
*/
::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}
::-webkit-scrollbar-thumb {
    border: 4px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb:hover {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
/* ng-scrollbar */
.ng-scroll-content {
    max-width: 100%;
}
/*
 *	Scrollbar
 */

/*
 *	Table
*/
.datatTableFilter {
    background-color: #fafafa;
    color: #8c96aa;
    border: 1px solid #eceef0;
    border-radius: 3px;

    input {
        font-family: "Open Sans" !important;
        font-size: 15px;
    }
}

.mat-table {
    overflow: auto;
    max-height: calc(100% - 118px);
    font-family: "Open Sans" !important;
    border: 1px solid #eceef0;
    border-bottom: none;
    border-radius: 3px;
    margin-right: 0.25rem !important;

    .mat-header-row {
        position: sticky;
        padding: 0px !important;
        top: 0;
        z-index: 1040;
        min-height: fit-content !important;
        background-color: #ffffff !important;
        border-bottom: 1px solid #eceef0;
        margin-bottom: 0px !important;

        .mat-header-cell {
            color: #8c96aa !important;
            font-size: 11px !important;
            justify-content: start !important;
            border-right: 1px solid #eceef0 !important;
            padding-left: 16px !important;
            padding-top: 3px !important;
            padding-bottom: 3px !important;
            margin-top: 13px !important;
            margin-bottom: 13px !important;
        }
        .mat-header-cell.actions {
            justify-content: flex-end !important;
        }

        th.mat-header-cell,
        .mat-header-cell:last-of-type {
            border-right: none !important;
        }

        .mat-header-cell.actions {
            padding-right: 10px !important;
        }
    }
    .mat-row {
        border: none;
        margin: 0px !important;
        padding: 0px !important;
        min-height: 40px !important;

        .mat-cell {
            color: #5a6478;
            font-size: 13px !important;
            line-height: 18px !important;
        }
    }
    .mat-row:hover {
        background-color: #eceef0 !important;
    }
    .mat-row:nth-of-type(even) {
        background-color: #fafafa;
    }

    .mat-cell {
        justify-content: space-between !important;
    }

    .mat-cell.actions {
        justify-content: flex-end !important;
        padding-right: 0px !important;
    }

    mat-cell:first-of-type,
    td.mat-cell {
        padding-left: 13px !important;
    }

    .mat-cell {
        -webkit-user-select: initial !important;
        -moz-user-select: initial !important;
        -ms-user-select: initial !important;
        -o-user-select: initial !important;
        user-select: initial !important;
    }
    //responsive mat table
    .mobile-label {
        display: none;
        text-align: left !important;
        padding-left: 5px;
    }

    @media (max-width: 767px) {
        .mobile-label {
            max-width: 120px;
            display: inline-block;
            font-weight: bold;
            min-width: 80px;
        }

        .mat-header-row {
            display: none;
        }

        .mat-row {
            flex-direction: column;
            align-items: start;
        }
    }
}
.mat-paginator-container {
    font-family: "Open Sans" !important;
    font-size: 15px !important;
    border: 1px solid #eceef0 !important;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #8c96aa !important;

    .mat-form-field .mat-select-value {
        color: #b4bec8 !important;
    }

    mat-form-field {
        border: 1px solid #eceef0 !important;
    }
}
/*
 *	Table
*/
/*
*  Modal
*/
.modal-header {
    background-color: #f4f7fa;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: bolder;
    color: #000;
}

.modal-footer {
    font-size: 13px;
    font-weight: bold;

    .button.cancel {
        background: transparent;
        border: 1px solid #8c96a0;
        color: #8c96a0;
    }

    .button.delete {
        background-color: #00c87d;
        border: none;
    }
}
/*
*  Modal
*/
/*
    ng-select component
    https://github.com/ng-select/ng-select
    https://ng-select.github.io/ng-select#/data-sources
    https://stackblitz.com/edit/ng-select?file=app/app.component.ts

*/

div.ng-select-wrapper {
    border: 1px solid #8c96aa !important;
    border-radius: 4px;

    &-disabled {
        background-color: #f4f5f7 !important;
    }
    .ng-select-disabled {
        .ng-placeholder {
            background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
        }
    }

    ng-select.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                overflow: hidden;

                .ng-value:first-of-type {
                    margin-top: 5px;
                    margin-left: 10px;
                }

                .ng-value {
                    display: flex;
                    align-items: center;
                    background-color: #274397;

                    .ng-value-label {
                        font-family: "Open Sans" !important;
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: bold;
                    }

                    .ng-value-icon {
                        color: #ffffff;
                        font-size: 13px !important;
                        font-weight: bold;
                    }
                }

                .ng-placeholder {
                    font-family: "Open Sans" !important;
                }
            }
        }

        .ng-select-container.ng-has-value {
            font-weight: 500 !important;
        }
    }

    ng-select.ng-select {
        margin-top: -10px;
        padding: 1px;
        width: inherit;

        .ng-select-container:after {
            border: none;
        }

        .ng-select-container {
            font-size: 15px;
            font-family: "Open Sans";
            font-weight: 400;
            color: #274397;

            .ng-value-container {
                .ng-placeholder {
                    background-color: #ffffff!important;
                    margin: 0px 10px !important;
                }

                .ng-input {
                    color: #274397;
                    padding: 0px 10px;
                }
            }
        }

        .ng-select-container.ng-has-value {
            font-weight: 500 !important;
            padding-left: 10px;
        }
    }
}

.rer-planning {
    ng-dropdown-panel.ng-dropdown-panel {
        margin-top: 30px;
    }
}
// if component is appendTo="body"
ng-dropdown-panel.ng-dropdown-panel {
    margin-top: 10px;
    border: 1px solid #274397;
    border-radius: 3px;
    box-shadow: none !important;

    .ng-dropdown-header {
        margin-right: 14px;
        height: 35px !important;
        font-family: "Open Sans" !important;
        font-size: 15px !important;
        color: #8c96aa !important;
        background: white;
    }

    .ng-dropdown-panel-items {
        .ng-option {
            height: 35px !important;
            font-family: "Open Sans" !important;
            font-size: 15px !important;
            color: #8c96aa !important;
            background: white;
        }
        .ng-option:nth-of-type(even) {
            background-color: #fafafa;
        }
        .ng-option-marked,
        .ng-option-selected {
            background-color: #eceef0 !important;
            color: #274397 !important;
        }
    }
    .ng-dropdown-footer {
        border-top: none!important;
        padding: 0px 0px 0px 12px !important;
        color: #274397!important;
        cursor: pointer!important;
        line-height: 1!important;
        min-height: 1em!important;
        margin-top: 10px!important;
        font-size: 15px!important;
        font-weight: bold!important;
    }

    .ng-dropdown-panel-items::-webkit-scrollbar-thumb {
        width: 8px;
        background-color: #274397 !important;
        border-radius: 0px !important;
    }
}
//class when dropdown overlaps datepicker
.z-index-overlap-datepicker .ng-select .ng-dropdown-panel {
    z-index: 999999999;
}
.z-index-overlap-datepicker-multiple .ng-select .ng-dropdown-panel {
    z-index: 100000000;
}

//utility classes for selects that have dropdown not where it should be
.select-top-25 .ng-select .ng-dropdown-panel {
    margin-top: 25px !important;
}
.select-top-30 .ng-select .ng-dropdown-panel {
    margin-top: 30px !important;
}
.select-top-40 .ng-select .ng-dropdown-panel {
    margin-top: 40px !important;
}
.arrow-bottom-4 .ng-select .ng-arrow-wrapper {
    bottom: 4px !important;
}
.arrow-bottom-12 .ng-select .ng-arrow-wrapper {
    bottom: 12px !important;
}
.arrow-bottom-14 .ng-select .ng-arrow-wrapper {
    bottom: 14px !important;
}
/*
 *	ng-select
*/
/*
 *	badge
*/
.badge {
    display: inline-block;
    //padding: .25em .4em;
    padding: 0.3em 0.5em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    color: #fff !important;
    &-default {
        background-color: #2bbbad !important;
    }
    &-grey {
        background-color: #9e9e9e !important;
    }
    &-primary {
        background-color: #4285f4 !important;
    }
    &-black {
        background-color: #000000 !important;
    }
    &-success {
        background-color: #00c851 !important;
    }
    &-white {
        color: #274397 !important;
        background-color: #ffffff !important;
    }
}
/*
 *	badge
*/
/*
 *   Header
 *   Notification Menu
*/
.customize-notification + div.cdk-overlay-connected-position-bounding-box {
    top: 64px !important;
    right: 0px !important;
}
.mat-menu-panel.notification-menu {
    border-radius: unset;

    .mat-menu-content {
        padding: 0px;

        .media.notification-item {
            font-family: "Open Sans";
            border-bottom: 1px solid #c4c4c4;

            i.font-size-24.notification-icon {
                color: #ffaf32;
            }

            div:first-child {
                height: 100%;
            }

            div.media-body {
                overflow: hidden;

                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                p.media-heading {
                    font-size: 10px;
                    line-height: 14px;
                    color: #8c96aa;
                }

                p:nth-child(2) {
                    font-size: 12px;
                    line-height: 16px;
                    color: #000000;
                }
            }
        }

        .media.notification-item:first-child {
            border-bottom: none;
        }
    }
}

/*
 *	Start Side bar & Top Bar
 *
 */

#sidebar {
    height: 100%;
    flex-basis: 360px;
    min-width: 360px;
    -ms-flex-preferred-size: 100%;
    position: relative;
    transition: 0.5s ease;
    @media screen and (max-width: 768px) {
        min-width: 100%;
        flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
    }
}

#sidebar.activate {
    transition: 0.5s ease;
    margin-left: -360px;
    @media screen and (max-width: 768px) {
        min-width: 100%;
        margin-left: -100%;
        #closeSideBar {
            position: absolute;
            top: -24px;
            right: -24px;
        }
    }
}

#sidebar.shadow {
    @media screen and (min-width: 768px) {
        -webkit-box-shadow: -16px 22px 54px 2px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: -16px 22px 54px 2px rgba(0, 0, 0, 0.21);
        box-shadow: -16px 22px 54px 2px rgba(0, 0, 0, 0.21);
    }
}

// in new tab
#sidebar-vehicledetail {
    height: 100%;
    -ms-flex-preferred-size: 360px;
    flex-basis: 360px;
    min-width: 360px;
    position: relative;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    @media screen and (max-width: 768px) {
        -ms-flex-preferred-size: 100%;
        min-width: 100%;
        margin-left: -101%;
        flex-basis: 100%;
        #closeSideBar {
            position: absolute;
            top: -24px;
            right: -24px;
        }
    }
}

#sidebar.tds {
    flex-basis: 64px;
    min-width: 64px;
    @media (max-width: 946px) {
        margin-left: -64px;
    }
}

#sidebar.tms{
    flex-basis: 64px;
    min-width: 64px;
}

#sidebar.tms.activate,
#sidebar.tds.activate {
    margin-left: -64px;
}

#rightside {
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

#closeSideBar {
    width: 18px;
    height: 36px;
    text-align: center;
    z-index: 1039;

    i {
        font-size: 16px;
        color: #fff !important;
        font-weight: bold;
    }

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        right: -12px;
    }

    @media screen and (max-width: 768px) {
        position: relative;
        top: 0%;
        i {
            vertical-align: -webkit-baseline-middle;
        }
    }
}

#resizeSideBar {
    width: 18px;
    height: 36px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -38px;
    right: -12px;
    z-index: 1039;
    i {
        font-size: 32px;
        color: #3b5297 !important;
        margin-left: -8px;
    }

    @media screen and (max-width: 576px) {
        display: none;
    }
}

.iconSideBarContainer {
    &:hover {
        transition: opacity 0.5s ease;
        opacity: 1;
    }

    opacity: 0.4;
}

.iconDragSideBarContainer {
    background-color: #3b5297;
}

.topBar {
    border-bottom: 1px solid #eceef0;
    background-color: #f4f7fa;

    .topbarTitle {
        font-size: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .addElement {
        color: #274397 !important;
        border: 1px solid #274397 !important;
    }

    input {
        border: none;
        font-family: "Open Sans";
        background-color: #f4f7fa;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        font-family: "Open Sans";
        font-size: 13px;
        font-style: normal;
        font-variant: normal;
        font-weight: 500;
        line-height: 14.4px;
        color: #274397;
    }

    table.filter {
        font-family: "Open Sans";

        thead {
            font-size: 11px;
            color: #fff;
            background-color: #ffaf32;
            line-height: 0.9;
            border-bottom: 1px solid #eceef0;
        }
        tbody {
            font-size: 11px;

            tr {
                border-bottom: 1px solid #eceef0;
                white-space: nowrap;

                td:first-child {
                    background-color: #f4f7fa;
                }
            }
        }
    }
}
/*
 *	Start Navbar burger menu icon
 */

.navbar-toggler-icon {
    margin-top: 8px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    cursor: pointer;
}

/*
 *	End Navbar burger menu icon
 */

/*
*	  Material Checkbox
*
*/
mat-checkbox {
    .mat-checkbox-layout {
        white-space: pre-wrap !important;

        .mat-checkbox-inner-container {
            .mat-checkbox-frame {
                border-radius: none;
                border-width: 1px;
            }

            .mat-checkbox-background {
                background: none;
            }
        }
    }

    .mat-checkbox-checkmark-path {
        stroke: #ffffff !important;
    }
}

mat-checkbox.mat-checkbox-checked {
    .mat-checkbox-frame {
        border-radius: none;
        border: none;
    }

    .mat-checkbox-background {
        background-color: #274397 !important;
    }
}
mat-checkbox.mat-checkbox-checked.mat-checkbox-disabled {
    .mat-checkbox-background {
        background-color: #b4bec8 !important;
    }
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #3f51b5 !important;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background: #3f51b5 !important;
}
/*
*	  Material Checkbox
*/

/*
*	  Material inputs
*/
.mat-input-underline,
.mat-form-field-underline,
.mat-form-field-ripple {
    display: none !important;
    height: 0px !important;
}
.mat-form-field {
    border: 1px solid #8c96aa !important;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 15px;
    font-family: "Open Sans" !important;
    line-height: initial;
    &.mat-form-field-disabled {
        background-color: #f4f5f7 !important;
        .mat-form-field-label span {
            background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
            background-color: #f4f5f7 !important;
        }
    }
    .mat-select-value {
        color: #274397 !important;
    }
    .mat-input-wrapper,
    .mat-form-field-wrapper {
        padding: 0px;
        input[type="address"],
        input[type="details"],
        input[type="number"],
        input[type="phone"],
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="url"],
        input[type="time"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="tel"],
        input[type="number"],
        input[type="search-md"],
        input[type="search"],
        textarea[type="details"] {
            resize: none;
            border-bottom: none !important;
            height: inherit !important;
            font-size: inherit !important;
            color: #274397 !important;
            background-color: transparent !important;
            &:disabled {
                color: #b7b7b7 !important;
            }
        }

        .mat-form-field-infix {
            border-top: none !important;
        }
    }
    .mat-form-field-label {
        span {
            font-family: "Open Sans" !important;
            color: #8c96aa !important;
            background-color: #ffffff !important;
            line-height: 20px !important;
        }
    }

    &.mat-form-field-invalid {
        border: 1px solid #ff194b !important;
    }
}
.mat-form-field.mat-form-field-should-float .mat-form-field-infix label {
    font-weight: 500;
}
/*
*	  Material inputs
*/
/*
*	  Components styling -------------------------------------------->
*/
/*
*	 Start Documents Module -------------------------------------------->
*/
div > app-document {
    .accordion .card .card-header {
        font-family: "Open Sans";
        font-size: 13px;
        color: #000;
        padding: 0;

        h5 {
            height: 37px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .accordion .card .card-header i {
        display: none;
    }

    .accordion .card .active .card-header {
        background-color: #b4b8c8;
    }

    .accordion .card .card-header h5 div:first-child {
        background-color: #f4f7fa;
        border-right: 1px solid #eee;

        span {
            font-size: 36px;
        }
    }

    .accordion .card .card-header h5 div:nth-child(2) {
        padding-left: 10px;
    }

    .accordion .card .active .card-header h5 div {
        background-color: #b4b8c8;
    }

    .accordion .card .card-body {
        padding: 0;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            div:first-child {
                background-color: #f4f7fa;
                border-right: 1px solid #eee;
                border-bottom: 1px solid #eceef0;

                span {
                    font-size: 36px;
                }
            }

            div:nth-child(2) {
                padding-left: 10px;
            }

            div:nth-child(3) {
                padding-right: 10px;

                span {
                    font-family: "Open Sans";
                    font-size: 10px;
                    font-weight: bold;
                    padding: 4px 10px;
                    color: #fff;
                    background-color: #274397;
                }
            }
        }

        .item.active,
        .item.active div:first-child {
            background-color: #b4b8c8;
            color: #fff;
        }

        .item.active div:nth-child(3) span {
            background-color: #fff;
            color: #b4b8c8;
        }

        .item.notcount div:nth-child(3) span {
            background-color: #b4b8c8;
            color: #fff;
        }

        .item:first-child {
            border-bottom: 1px solid #eceef0;
        }
    }
}
/*
*	 Stop Documents Module -------------------------------------------->
*/

/*
 * Map Module
 * Google Maps Controls -------------------------------------------->
 */

div.gm-svpc,
.gmnoprint.gm-bundled-control > .gmnoprint,
.gm-style > .gm-control-active.gm-fullscreen-control {
    box-shadow: none !important;
    width: 30px !important;
    height: 30px !important;
    -webkit-background-clip: padding !important;
    background-clip: padding-box !important;
    outline: 4px solid rgba(0, 0, 0, 0.1) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px !important;
}
div.gm-svpc {
    left: unset !important;
    top: 64px !important;

    img {
        height: 20px !important;
        width: 20px !important;
    }
}
div.gm-style {
    .gm-control-active.gm-fullscreen-control {
        padding: 5px !important;
        right: 5px !important;
    }

    .traffic-layer-top {
        top: 178px !important;
        right: 13px !important;
    }

    .fit-to-bounds-top {
        top: 217px !important;
        right: 13px !important;
    }
    .fit-to-bounds-top-wgm {
        /* wgm = without google maps street view and traffic */
        top: 111px !important;
        right: 13px !important;
    }

    .hu-go-top {
        top: 256px !important;
        right: 13px !important;
    }

    .toggle-route-top {
        top: 242px !important;
        right: 13px !important;
    }

    .gmnoprint.gm-bundled-control {
        top: 47px !important;
        right: 43px !important;
        margin: 0px !important;

        .gmnoprint {
            left: unset !important;
            height: unset !important;
            width: 30px !important;

            div:first-child {
                box-shadow: none !important;
                height: unset !important;
                width: 30px !important;
                border-radius: 0px !important;

                div {
                    width: unset !important;
                }

                button {
                    height: 30px !important;
                    width: 30px !important;
                }
            }
        }
    }

    .gmnoprint.gm-style-mtc {
        right: 48px !important;
        box-shadow: none !important;
        -webkit-background-clip: padding !important;
        background-clip: padding-box !important;
        border: 1px solid #b4bec8 !important;
        outline: 4px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 0px !important;
    }

    .gmnoprint.gm-style-mtc button {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 5px !important;
        width: 120px !important;
        font-family: Open Sans !important;
        position: relative;
        font-size: 13px !important;
        color: rgb(0, 75, 125) !important;
        cursor: pointer;
        font-weight: bold !important;
        user-select: none;
    }

    .gmnoprint.gm-style-mtc ul {
        width: 120px !important;
        margin-top: 8px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .gmnoprint.gm-style-mtc ul li {
        height: 36px;
        align-items: center;
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-top: 8px !important;
        flex-grow: 1;
        overflow: hidden;
        border-bottom: 1px solid rgb(140, 150, 170);
        font-size: 13px !important;
        color: rgb(90, 100, 120) !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Open Sans !important;
        font-weight: bold !important;
    }

    .gmnoprint.gm-style-mtc ul li:nth-child(1) {
        border-top: 1px solid #8c96aa;
    }

    .gmnoprint.gm-style-mtc ul div {
        border: none !important;
    }
    .gmnoprint.gm-style-mtc ul li[role="menuitemcheckbox"] {
        display: flex;
        padding-left: 0px !important;
    }

    .gmnoprint.gm-style-mtc ul li[role="menuitemcheckbox"] span {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
        margin-top: -9px !important;
    }
    .gmnoprint.gm-style-mtc ul li[role="menuitemcheckbox"] label {
        font-family: Open Sans !important;
        font-weight: bold !important;
        font-size: 13px !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(1) {
        //font-family: 'Montserrat'!important;
        font-weight: bold !important;
        color: #004b7d !important;
        font-size: 13px !important;
        padding: 6px 2px 6px 5px !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) {
        border-radius: 0px !important;
        padding: 0px 6px 0px 0px !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div {
        font-size: 13px !important;
        font-family: "Open Sans" !important;
        font-weight: normal !important;
        color: #000000 !important;
        border-left: 30px solid #f4f7fa;
        border-bottom: 1px solid #b4bec8;
        padding-left: 10px !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:nth-child(6) {
        border-bottom: none !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:hover {
        color: #004b7d !important;
        background-color: #f4f7fa !important;
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:nth-child(8),
    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:nth-child(10),
    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:nth-child(11),
    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div:nth-child(12) {
        line-height: 1;
        border: none !important;
        padding: 0px !important;

        span {
            padding-left: 8px !important;
            padding-right: 8px !important;
            padding-top: 2px !important;
            padding-bottom: 4px !important;
            background-color: #f4f7fa !important;
            border-top: 1px solid #b4bec8 !important;
            vertical-align: unset !important;

            img {
                vertical-align: sub !important;
            }
        }

        label {
            margin-bottom: 0px !important;
            margin-left: 10px !important;
            padding-bottom: 6px !important;
            padding-top: 4px !important;
        }
    }

    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div.zip-boundary,
    div.gmnoprint.gm-style-mtc > div:nth-child(2) > div.zip-code {
        border-top: 1px solid #b4bec8 !important;

        span {
            padding-top: 1px !important;
        }
    }
}
.infocontainer,
.vehiclecontainer {
    .label {
        display: inline-block;
        border-radius: 6px;
        padding: 3px 0;
        width: 24px;
        text-align: center;
        text-decoration: none;
        font-style: normal;

        &-1 {
            background-color: #274397 !important;
            border: solid 1px #274397;
        }
        &0 {
            background-color: #da2424 !important;
            border: solid 1px #da2424;
        }
        &1 {
            background-color: #f58220 !important;
            border: solid 1px #f58220;
        }
        &2 {
            background-color: #00c87d !important;
            border: solid 1px #00c87d;
        }
        &3 {
            background-color: #bfbfbf !important;
            border: solid 1px #bfbfbf;
        }
    }

    .overview .label {
        line-height: 0.9;
        margin-bottom: 10px;
    }

    .table-detail .label {
        line-height: 1.2;
        margin-bottom: 0px;
    }
}
.custom-dialog-container .mat-dialog-container {
    padding: 0px !important;
    overflow: visible;

    .mat-dialog-actions {
        display: block;
        margin-bottom: unset;
    }

    .mat-dialog-content {
        margin: unset;
        overflow: unset;
    }
}
/*
* Google Maps Controls -------------------------------------------->
*/
/*
* Map Module -------------------------------------------->
* Style Info Window from Markers
*/

.gm-style-iw + div {
    display: none;
}
.gm-style-iw {
    background-color: #274397 !important;
    text-align: center;
    font-weight: bold !important;
    top: 27px !important;
    border-radius: 6px;

    p {
        font-size: 13px;
        color: #fff;
        padding: 8px 3px 3px;
    }
}

.gm-style-iw:has(div > div > app-coordonate-info > .card) {
    background-color: transparent !important;
}

.gm-style > div:first-child > div:last-child > div:first-child > div:last-child > div > div:first-child > div {
    background-color: transparent !important;
    box-shadow: none !important;
}

.gm-style
    > div:first-child
    > div:last-child
    > div:first-child
    > div:last-child
    > div
    > div:first-child
    > div:nth-child(3)
    > div:nth-child(2)
    > div {
    background-color: #274397 !important;
    transform: skewX(-42.6deg) !important;
    top: 13px;
    box-shadow: none !important;
}

.gm-style
    > div:first-child
    > div:last-child
    > div:first-child
    > div:last-child
    > div
    > div:first-child
    > div:nth-child(3)
    > div:first-child
    > div {
    transform: skewX(42.6deg) !important;
    background-color: #274397 !important;
    box-shadow: none !important;
    top: 13px;
}

.gm-style
    > div:first-child
    > div:last-child
    > div:first-child
    > div:last-child
    > div
    > div:first-child
    > div:first-child,
.gm-style > div:first-child > div:last-child > div:first-child > div:last-child > div > button {
    display: none !important;
}

.infoBox {
    text-align: center;
    position: absolute;
    overflow: visible !important;
    vertical-align: middle;
    color: #ffffff;
    margin-left: 10px;

    p {
        padding: 8px 3px 3px;
    }

    .label-radius-common {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        width: 25px;
    }

    .label {
        font-family: "Open Sans";
        font-size: 15px;
        background-color: #274397 !important;
        border: solid 1px #1f3577;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 5px 7px 5px 7px;
    }

    .label-geozone {
        font-family: "Montserrat";
        font-weight: bold;
        font-size: 11px;
        background-color: #274397 !important;
        border: solid 1px #1f3577;
        border-radius: 6px;
        padding: 5px 7px 5px 7px;
    }

    .labelCluster {
        background-color: #274397 !important;
        padding: 5px 7px 5px 7px;
    }

    .label-1 {
        background-color: #274397 !important;
        border: solid 1px #1f3577;
    }
    .label0 {
        background-color: #da2424 !important;
        border: solid 1px #9e0000;
    }
    .label1 {
        background-color: #f58220 !important;
        border: solid 1px rgb(194, 90, 0);
    }
    .label2 {
        background-color: #00c87d !important;
        border: solid 1px #008a7c;
    }

    .content {
        background-color: #274397 !important;
    }
}

.infoBox::after {
    content: " ";
    position: absolute;
    top: 100%;
    z-index: 2000;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    clear: both;
    margin-left: -10px;
    border-top: 10px solid #274397;
}

.infoBox > div {
    cursor: pointer;
}

.infoBox > img {
    display: none;
}

.contextMenuInfoBox {
    text-align: center;
    position: absolute;
    font-size: 15px;
    overflow: visible !important;
    vertical-align: middle;
    font-family: "Open Sans";
    color: #ffffff;
    width: unset !important;
    margin-left: 10px;
    text-align: left;
    background-color: #8c96a0;

    .header {
        background-color: #8c96a0;
        color: #fff;
        padding: 5px;
        h6 {
            font-weight: bold;
        }
    }

    a.list-group-item {
        padding: 0px !important;
        background-color: #ffffff;
        display: inline-block;
        line-height: 2rem;
    }

    a,
    a:focus,
    a:hover {
        color: inherit;
        text-decoration: none;
        font-size: 14px;
    }

    a {
        color: #000 !important;
        .text {
            margin-left: 5px;
        }
        .icon {
            background-color: #f4f7fa;
            padding: 0.3rem;
        }
    }

    a:hover {
        color: #274397 !important;
    }
}
.map-scrollbar {
    .ng-scroll-content {
        width: 100%;
    }
}
//  Map Module - Packages Module
//  Selected vehicle - Packages

mat-tab-group.mat-tab-group.vehicle-route-info,
mat-tab-group.mat-tab-group.vehicle-info,
mat-tab-group.mat-tab-group.package-type {
    height: calc(100% - 50px);

    .mat-tab-header {
        margin-bottom: 0px;

        .mat-tab-label-container {
            .mat-tab-list {
                border-bottom: none !important;

                .mat-tab-labels {
                    .mat-tab-label-active {
                        padding-top: unset;
                        margin-top: unset;
                        background-color: #fff !important;
                        border-right: 1px solid#ECEEF0 !important;
                        border-top: 1px solid#ECEEF0;
                        border-left: none !important;
                        border-bottom: none !important;
                    }

                    .mat-tab-label {
                        font-weight: 700;
                        font-size: 13px;
                        margin-top: unset;
                        opacity: 1 !important;
                        flex-grow: 1 !important;
                        border-left: none !important;
                        border-right: 1px solid#ECEEF0 !important;
                        border-bottom: 1px solid#ECEEF0;
                        border-top: 1px solid#ECEEF0;
                        text-transform: uppercase;
                        i {
                            font-size: 24px;
                            font-weight: normal;
                        }
                    }
                }

                .mat-ink-bar {
                    background-color: #f4f7fa !important;
                }
            }
        }
    }

    .mat-tab-body-wrapper {
        .mat-tab-body {
            .mat-tab-body-content {
                margin-left: 0px !important;
                margin-right: 0px !important;
                background-color: #ffffff !important;
                overflow: hidden !important;
                @media (max-height: 789px) {
                    overflow: auto !important;
                }

                .mat-focused {
                    color: #274397 !important;
                    border-color: #274397 !important;
                    label {
                        color: #274397 !important;
                    }
                }
            }
        }
    }

    .mat-checkbox-layout .mat-checkbox-label {
        line-height: 1.2rem;
        padding-left: 10px;
        white-space: pre-wrap;
    }
}
mat-tab-group.mat-tab-group.vehicle-route-info {
    .mat-tab-label,
    .mat-tab-label-active {
        .mat-tab-label-content {
            color: #274397 !important;
        }
        color: #274397 !important;
    }
}

mat-tab-group.mat-tab-group.package-type {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-list {
                .mat-tab-labels {
                    .mat-tab-label-active:nth-child(2) {
                        border-right: none !important;
                    }
                    .mat-tab-label:nth-child(2) {
                        border-right: none !important;
                    }
                }
            }
        }
    }
}
/*
* Map Module -------------------------------------------->
*/

/*
 * TMS Module -------------------------------------------->
 */
 div > app-tms {
    .mat-horizontal-stepper-header-container {
        margin-bottom: 20px;
    }
    .mat-horizontal-stepper-content[aria-expanded="true"] {
        height: inherit !important;
        display: flex;
        justify-content: center;
    }
    .mat-stepper-horizontal .mat-horizontal-content-container {
        height: calc(100% - 6rem) !important;
        padding: 0 !important;
        position: relative;
    }
    .mat-horizontal-stepper-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: -15px;
        min-height: 100px;
    }
    .mat-step-header {
        pointer-events: none !important;
    }
    .mat-step-header .mat-step-icon {
        background-color: #b4bec8;
    }
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: #f58220;
    }
    .mat-step-label {
        position: relative;
        top: 10px;
    }
    .mat-step-icon {
        width: 50px;
        height: 50px;
    }
    .mat-stepper-horizontal-line {
        margin: 0 !important;
    }
    .mat-horizontal-stepper-header::before,
    .mat-horizontal-stepper-header::after,
    .mat-stepper-horizontal-line {
        border-top-color: #f58220 !important;
    }
    .mat-step-icon-state-start {
        background-color: #f58220 !important;
    }
    .mat-horizontal-stepper-header[aria-selected="true"] ~ .mat-stepper-horizontal-line {
        border-top-color: #c4c4c4 !important;
    }
    .mat-horizontal-stepper-header .mat-step-icon,
    .mat-horizontal-stepper-header .mat-step-icon-not-touched {
        margin-right: 0 !important;
    }
    .alert-info {
        background-color: #56ccf2;
    }
    .mat-horizontal-stepper-header[aria-selected="true"] ~ .mat-step-header .mat-step-label,
    .mat-step-header .mat-step-optional {
        color: #b4bec8 !important;
        font-size: 14px !important;
    }
    .mat-step-header .mat-step-label.mat-step-label-selected {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .mat-expansion-panel {
        overflow: visible;
    }
    // wizard

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        filter: none;
        box-shadow: 0 0 0 100px rgba(255, 255, 255, 1) inset !important;
        -webkit-text-fill-color: #274397 !important;
    } //autofill color

    .shippingcontainer {
        .mat-tab-body-content {
            overflow: hidden;
        }
    }
    .routeContainer {
        .options {
            .mat-checkbox-inner-container {
                margin-right: 2px !important;
            }
        }
        .mat-form-field .mat-form-field-label span {
            background-color: #fafafa !important;
        }
        .mat-form-field.mat-form-field-should-float {
            .mat-form-field-infix {
                position: inherit !important;

                label {
                    background-color: #fafafa;
                    margin-top: 0px;
                    width: fit-content;
                    color: rgba(0, 0, 0, 0.54) !important;
                }
            }
            &.mat-form-field-disabled {
                background-color: #fafafa !important;
                .mat-form-field-label span {
                    background: #fafafa !important;
                }
            }
        }
    }
    .companycontainer,
    .addresslistcontainer {
        .mat-checkbox-label,
        span,
        sub,
        p {
            font-family: "Open Sans";
        }
        span.summary-text {
            font-family: "Montserrat";
        }
    }

    .costsContainer {
        .mat-table {
            .mat-header-row {
                .mat-sort-header-container {
                    justify-content: flex-start !important;
                }
            }
        }
    }
    .settingscontainerFields {
        .ng-select-container.ng-has-value {
            color: #274397;
        }
    }
    .vehicleStats {
        .card:not([class*="card-outline-"]) {
            box-shadow: none !important;
        }
    }
    ng-dropdown-panel.ng-dropdown-panel.shipping-costs,
    ng-dropdown-panel.ng-dropdown-panel.shipping-goods,
    ng-dropdown-panel.ng-dropdown-panel.settings-adresses {
        margin-left: 0px;
    }
    .hintIcon {
        cursor: pointer;
    }
    .tms-tooltip {
        z-index: 99999999999999 !important;
    }

    .tms-tooltip .tooltip-inner {
        background: #274397;
        font-size: 13px !important;
        max-width: 260px !important;
        text-align: left !important;
        border-radius: 5px !important;
        padding: 0.5rem 0.3rem 0.1rem 0.25rem !important;
        ul {
            padding-inline-start: 25px !important;
        }
    }
    .tms-tooltip.bs-tooltip-top .arrow::before {
        border-top-color: #274397 !important;
    }
    .tms-tooltip.bs-tooltip-right .arrow::before {
        border-right-color: #274397 !important;
    }
    .tms-tooltip.bs-tooltip-bottom .arrow::before {
        border-bottom-color: #274397 !important;
    }
    .tms-tooltip.bs-tooltip-left .arrow::before {
        border-left-color: #274397 !important;
    }
    .ng-option {
        color: #4b4b4b !important;
    }
    .ng-placeholder {
        color: #4b4b4b !important;
    }
    .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
        bottom: 15px !important;
        color: #4b4b4b !important;
    }
    .mat-tab-label.mat-tab-label-active {
        color: #274397 !important;
    }
    .mat-tab-label {
        color: #4b4b4b !important;
    }
    .mat-form-field .mat-form-field-label span {
        color: #4b4b4b !important;
    }
    .widgetfinance {
        .datePickerContainer {
            .mat-input-element,
            .mat-form-field-element {
                font-family: "Open Sans" !important;
                color: #000000;
            }
        }
    }
    .widgetfinanceSelect {
        .mat-option {
            .mat-option-text {
                font-family: "Open Sans" !important;
            }
        }
    }
    .widget {
        .card-header {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            background: #ffffff;
            padding-top: 0px;
            padding-left: 10px;
            padding-right: 10px;

            i {
                color: #b4bec8;
            }
        }
        .mat-form-field-label {
            font-family: "Open Sans" !important;
            text-transform: capitalize !important;
            color: #000000;
        }
        .card-body {
            .widget-small {
                flex-shrink: 0;
            }
            .total-number {
                font-weight: bold;
                font-size: 78px;
                color: #3b5297;
                line-height: 1;
            }

            .total-text {
                font-size: 18px;
                color: #3b5297;
                font-weight: bolder;
                text-transform: uppercase;
            }
            p {
                font-weight: bold;
                text-align: center;
                padding: 0px;
                margin: 0px 5px;
            }

            p:first-child {
                font-size: 56px;
            }

            p:nth-child(2) {
                font-size: 13px;
                font-weight: bold;
                color: #b4bec8;
            }

            .shippingsinfo {
                margin-top: -49px;
                mdb-tabset {
                    .white {
                        .active {
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                        li {
                            margin: 0px !important;
                        }
                    }
                }

                .tab-container {
                    .container-fluid {
                        .row {
                            div:last-child {
                                padding-right: 0px;

                                .tab-content {
                                    margin-right: -9px;
                                }
                            }
                        }
                    }
                    .nav {
                        background: transparent !important;
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        .nav-item {
                            .nav-link {
                                font-family: "Open Sans" !important;
                                font-size: 13px !important;
                                color: #000000 !important;
                                background-color: #fafafa !important;
                                padding: 10px 20px !important;
                                border-right: 1px solid rgba(0, 0, 0, 0.125);
                                border-top: 1px solid rgba(0, 0, 0, 0.125);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                            }
                            .nav-link:hover {
                                color: #ffffff !important;
                                background-color: #b4bec8 !important;
                            }
                            .nav-link.active {
                                color: #004b7d !important;
                                background-color: #ffffff !important;
                                border-bottom: 1px solid #ffffff;
                                cursor: auto;
                            }
                        }
                        .nav-item:first-child {
                            .nav-link {
                                border-left: 1px solid rgba(0, 0, 0, 0.125);
                            }
                        }
                    }
                }
            }
            canvas {
                max-height: 400px;
            }
        }
    }
}
/*
* TMS Module -------------------------------------------->
*/
/*
* TDS Module -------------------------------------------->
*/
div > app-tds {
    .vehicleStats {
        .card:not([class*="card-outline-"]) {
            box-shadow: none !important;
        }
    }
    .costsContainer {
        .mat-table {
            .mat-header-row {
                .mat-sort-header-container {
                    justify-content: flex-start !important;
                }
            }
        }
    }
    .shippingcontainer {
        .mat-tab-body-content {
            overflow: hidden;
        }
    }
    ng-dropdown-panel.ng-dropdown-panel.shipping-costs,
    ng-dropdown-panel.ng-dropdown-panel.shipping-goods,
    ng-dropdown-panel.ng-dropdown-panel.settings-adresses {
        margin-left: 0px;
    }
    .tms-tooltip {
        background: #274397;
    }
    .ng-option {
        color: #4b4b4b !important;
    }
    .ng-placeholder {
        color: #4b4b4b !important;
    }
    .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
        bottom: 15px !important;
        color: #4b4b4b !important;
    }
    .mat-tab-label.mat-tab-label-active {
        color: #274397 !important;
    }
    .mat-tab-label {
        color: #4b4b4b !important;
    }
    .mat-form-field .mat-form-field-label span {
        color: #4b4b4b !important;
    }
    .widgetfinance {
        .datePickerContainer {
            .mat-input-element,
            .mat-form-field-element {
                font-family: "Open Sans" !important;
                color: #000000;
            }
        }
    }

    .widgetfinanceSelect {
        .mat-option {
            .mat-option-text {
                font-family: "Open Sans" !important;
            }
        }
    }

    .widget {
        // font-family: 'Montserrat';

        .card-header {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            background: #ffffff;
            padding-top: 0px;
            padding-left: 10px;
            padding-right: 10px;

            i {
                color: #b4bec8;
            }
        }

        .mat-form-field-label {
            font-family: "Open Sans" !important;
            text-transform: capitalize !important;
            color: #000000;
        }

        .card-body {
            .widget-small {
                flex-shrink: 0;
            }
            .total-number {
                font-weight: bold;
                font-size: 78px;
                color: #3b5297;
                line-height: 1;
            }

            .total-text {
                font-size: 18px;
                color: #3b5297;
                font-weight: bolder;
                text-transform: uppercase;
            }
            p {
                font-weight: bold;
                text-align: center;
                padding: 0px;
                margin: 0px 5px;
            }

            p:first-child {
                font-size: 56px;
            }

            p:nth-child(2) {
                font-size: 13px;
                font-weight: bold;
                color: #b4bec8;
            }

            .shippingsinfo {
                margin-top: -49px;
                mdb-tabset {
                    .white {
                        .active {
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                        li {
                            margin: 0px !important;
                        }
                    }
                }

                .tab-container {
                    .container-fluid {
                        .row {
                            div:last-child {
                                padding-right: 0px;

                                .tab-content {
                                    margin-right: -9px;
                                }
                            }
                        }
                    }
                    .nav {
                        background: transparent !important;
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        .nav-item {
                            .nav-link {
                                font-family: "Open Sans" !important;
                                font-size: 13px !important;
                                color: #000000 !important;
                                background-color: #fafafa !important;
                                padding: 10px 20px !important;
                                border-right: 1px solid rgba(0, 0, 0, 0.125);
                                border-top: 1px solid rgba(0, 0, 0, 0.125);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                            }
                            .nav-link:hover {
                                color: #ffffff !important;
                                background-color: #b4bec8 !important;
                            }
                            .nav-link.active {
                                color: #004b7d !important;
                                background-color: #ffffff !important;
                                border-bottom: 1px solid #ffffff;
                                cursor: auto;
                            }
                        }
                        .nav-item:first-child {
                            .nav-link {
                                border-left: 1px solid rgba(0, 0, 0, 0.125);
                            }
                        }
                    }
                }
            }
            canvas {
                max-height: 285px;
            }
        }
    }
}

app-vehiclerouteinfo mdb-progressbar .progress-bar,
app-vehiclerouteinfo mdb-progressbar .progress {
    height: 10px !important;
}
/*
* TDS Module -------------------------------------------->
*/
/*
* Settings Module -------------------------------------------->
* Device Config
*/

.setting.ng-scrollbar {
    .ng-scroll-content {
        height: 100%;
    }
}

.device-list.mat-table {
    .mat-header-row {
        .mat-sort-header-container {
            justify-content: left;
        }
    }
}
/*
* Settings Module -------------------------------------------->
*/
/*
* Route Module -------------------------------------------->
*
*/
mat-tab-group.routeModule {
    mat-tab-header {
        .mat-tab-list {
            .mat-tab-labels {
                justify-content: center !important;
            }
        }
    }
}

.form-container,
.overviewContainer,
.datePickerContainer,
.widget,
.vehicleOverviewContainer,
.settingscontainer,
.tmsSettingsAddresses,
.tdsSettingsAddresses,
.customMail {
    .mat-form-field.mat-form-field-should-float {
        .mat-form-field-infix {
            position: inherit !important;

            label {
                background-color: #ffffff;
                margin-top: 0px;
                width: fit-content;
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
        &.mat-form-field-disabled {
            background-color: #f4f5f7 !important;
            .mat-form-field-label span {
                background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
            }
        }
    }
}

.datePickerContainer .calendar {
    z-index: 1050 !important;
}

.locationContainer {
    .mat-form-field.mat-form-field-should-float {
        .mat-form-field-infix {
            position: inherit !important;

            label {
                background-color: #ffffff;
                margin-top: 0px;
                width: fit-content;
                color: rgba(0, 0, 0, 0.54) !important;
            }
        }
        aria-owns &.mat-form-field-disabled {
            background-color: #f4f5f7 !important;
            .mat-form-field-label span {
                padding: 0px 5px 0px 5px;
                background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
            }
        }
    }
}

.routedevicelistcontainer,
.selectHistoryPeriod,
.createPositionEvent,
.buzzerCmd,
.imobilizerCmd,
.add-report {
    .mat-form-field.mat-form-field-should-float {
        .mat-form-field-infix {
            position: inherit !important;
            &:disabled {
                color: #b7b7b7 !important;
            }

            label {
                background-color: #ffffff;
                margin-top: 0px;
                width: fit-content;
                color: rgba(0, 0, 0, 0.54) !important;
                padding: 0px 5px !important;
            }
            &.mat-form-field-disabled {
                background-color: #f4f5f7 !important;
                .mat-form-field-label span {
                    padding: 0px 5px 0px 5px;
                    background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
                }
            }
        }
    }
}

.routeSummary,
.goodscontent,
.intermediateContainer,
.documentsContainer,
.invoicingContainer,
.routeCalculator,
.goodsContent,
.costsContainer,
.add-partner-dialog-content,
.settings-dialog-content,
.partnerOverviewContainer {
    .mat-form-field {
        border: 1px solid #8c96aa;
    }
    .mat-form-field.mat-form-field-should-float {
        .mat-form-field-infix {
            position: inherit !important;

            label {
                background-color: #ffffff;
                margin-top: 0px;
                width: fit-content;
                color: rgba(0, 0, 0, 0.54) !important;
            }
            &.mat-form-field-disabled {
                background-color: #f4f5f7 !important;
                .mat-form-field-label span {
                    background: linear-gradient(0deg, #f4f5f7 50%, transparent 50%) !important;
                }
            }
        }
    }
}

app-add-partner-dialog {
    h1.mat-dialog-title {
        text-align: center;
        background-color: #f4f7fa;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: bolder;
        color: #000;
        border-bottom: 1px solid #e9ecef;
    }

    .mat-dialog-actions {
        border-top: 1px solid #e9ecef;
    }
}
/*
* Route Module -------------------------------------------->
*
*/
/*
 * Settings module -------------------------------------------->
*/
div > app-settings {
    .settings-container {
        mat-tab-group {
            mat-tab-header {
                margin-bottom: 0px;

                .mat-tab-labels {
                    margin-right: 4rem;
                }
            }

            .mat-tab-body-wrapper {
                mat-tab-body {
                    .mat-tab-body-content {
                        margin-left: 0px !important;
                        margin-right: 0px !important;
                    }
                }
            }
        }
    }
    app-client-event-manager {
        .mat-tab-labels {
            justify-content: flex-start !important;
        }

        .mat-radio-button .mat-ripple {
            display: none;
        }
        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
            border-color: #3f51b5;
        }
        .mat-radio-button.mat-accent .mat-radio-inner-circle {
            background-color: #3f51b5;
        }
        .mat-radio-outer-circle {
            box-sizing: border-box;
            left: 0;
            position: absolute;
            top: 0;
            transition: border-color ease 280ms;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            border-color: #3f51b5;
        }
        .mat-radio-inner-circle {
            border-radius: 50%;
            box-sizing: border-box;
            left: 0;
            position: absolute;
            top: 0;
            transition: transform ease 280ms, background-color ease 280ms;
            transform: scale(0.001);
            background-color: #3f51b5;
        }
        .mat-radio-label {
            margin-bottom: 0px !important;
        }
    }
}
/*
 * Settings module -------------------------------------------->
*/
/* Module MAP Vehicle History -------------------------------------------->
/*  Mat select label */
app-vehiclehistory {
    .mat-form-field {
        border: 1px solid #8c96aa !important;

        .mat-form-field-label {
            font-family: "Open Sans" !important;
            color: #8c96aa !important;
            background-color: #ffffff !important;
            line-height: 20px !important;
        }
    }

    app-historyhighlightedsegment {
        .mat-form-field {
            border: 1px solid #8c96aa !important;

            .mat-form-field-label {
                background-color: #fafafb !important;
            }
        }
    }
}
.highlighted-segment.ng-select-wrapper {
    border: 1px solid #8c96aa !important;

    .ng-select {
        .ng-placeholder {
            font-family: "Open Sans" !important;
            color: #8c96aa !important;
            background-color: #fafafb !important;
            line-height: 20px !important;
        }
    }
}
app-createpositionevent, app-historyhighlightedsegment {
    div.ng-placeholder {
        background-color: #FAFAFA!important;
}   

    .mat-form-field-label-wrapper {
        .mat-form-field-label {
            span {
                background-color: #FAFAFA!important;
            }
        }
    }

    .mat-form-field.mat-form-field-should-float .mat-form-field-infix label {
        background-color: #FAFAFA!important;
    }
}
/* Module MAP Vehicle History -------------------------------------------->
/*
*    Signin Module -------------------------------------------->
*/
.sign-in-form {
    margin-top: 1.5rem;

    .mat-form-field-label,
    .mat-checkbox-label {
        font-family: "Open Sans" !important;
        font-size: 15px;
        color: #274397 !important;
        background-color: white;
    }

    label {
        width: fit-content !important;
        padding: 0px 5px;
    }

    .mat-checkbox-checked {
        .mat-checkbox-background {
            border-color: #274397 !important;
            background-color: #274397 !important;
            z-index: 0;
        }

        .mat-checkbox-checkmark-path {
            stroke: white !important;
            fill: none !important;
        }
    }

    .mat-input-element,
    .mat-form-field-element {
        font-family: "Open Sans" !important;
        font-size: 13px;
    }
}
/*
*    Signin Module -------------------------------------------->
*/
/*
*  Share link module -------------------------------------------->
*/
div > app-sharelink {
    .add-sharelink .ng-select-wrapper ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
        font-family: "Open Sans" !important;
        color: #8c96aa !important;
        font-size: 15px;
        line-height: 20px;
    }
    div.ng-select-wrapper
        ng-select.ng-select-multiple
        .ng-select-container
        .ng-value-container
        .ng-value
        .ng-value-label {
        font-family: "Open Sans" !important;
    }
}
div > app-report {
    .no-scroll .mat-tab-body-content {
        overflow-y: hidden !important;
    }
    .no-scroll .mat-tab-body {
        overflow: hidden !important;
    }
}
/*
*  Share link module -------------------------------------------->
*/
/*
*  PGS module -------------------------------------------->
*/
div > app-pgs,
.pgs-review-dialog {
    @font-face {
        font-family: "ctsicon-general-20210805";
        src: url("assets/fonts/ctsicon-general-20210805.eot");
        src: url("assets/fonts/ctsicon-general-20210805.eot#iefix") format("embedded-opentype"),
            url("assets/fonts/ctsicon-general-20210805.ttf") format("truetype"),
            url("assets/fonts/ctsicon-general-20210805.woff") format("woff"),
            url("assets/fonts/ctsicon-general-20210805.svg#ctsicon-general-20210805Regular") format("svg");
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
    .no-scroll .mat-tab-body-content {
        overflow-y: hidden !important;
    }
    .no-scroll .mat-tab-body {
        overflow: hidden !important;
    }

    // stars rating
    .rating_circle {
        width: 100px;
        height: 100px;
        border-radius: 70px;
        border: 1px none;
        font-size: 35px;
    }
    .rating_text {
        margin-top: 10px !important;
    }
    .stars-outer {
        display: inline-block;
        position: relative;
        font-family: "ctsicon-general-20210805";
        font-size: 20px;
        letter-spacing: 5px;
    }

    .stars-outer::before {
        content: "\e601 \e601 \e601 \e601 \e601";
    }

    .stars-inner {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        width: 70%;
    }

    .stars-inner::before {
        content: "\e600 \e600 \e600 \e600 \e600";
        color: #000000;
    }
    .dark {
        background-color: #000000;
    }

    .star-single {
        font-family: "ctsicon-general-20210805";
    }

    .star-single::before {
        content: "\e600";
        font-style: normal;
    }
    .mat-column-unloading_done,
    .mat-column-unloading_on_time,
    .mat-column-unloading_on_time_possible {
        max-width: 100px;
    }

    .review-list.mat-table {
        max-height: 100% !important;
    }
    .widget {
        .card-header {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            background: #ffffff;
            padding-top: 0px;
            padding-left: 10px;
            padding-right: 10px;

            i {
                color: #b4bec8;
            }
        }
        .mat-form-field-label {
            font-family: "Open Sans" !important;
            text-transform: capitalize !important;
            color: #000000;
        }
        .card-body {
            .widget-small {
                flex-shrink: 0;
            }
            .total-number {
                font-weight: bold;
                font-size: 78px;
                color: #3b5297;
                line-height: 1;
            }

            .total-text {
                font-size: 18px;
                color: #3b5297;
                font-weight: bolder;
                text-transform: uppercase;
            }
            p {
                font-weight: bold;
                text-align: center;
                padding: 0px;
                margin: 0px 5px;
            }

            p:first-child {
                font-size: 56px;
            }

            p:nth-child(2) {
                font-size: 13px;
                font-weight: bold;
                color: #b4bec8;
            }

            .shippingsinfo {
                margin-top: -49px;
                mdb-tabset {
                    .white {
                        .active {
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }
                        li {
                            margin: 0px !important;
                        }
                    }
                }

                .tab-container {
                    .container-fluid {
                        .row {
                            div:last-child {
                                padding-right: 0px;

                                .tab-content {
                                    margin-right: -9px;
                                }
                            }
                        }
                    }
                    .nav {
                        background: transparent !important;
                        border-radius: 0;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        .nav-item {
                            .nav-link {
                                font-family: "Open Sans" !important;
                                font-size: 13px !important;
                                color: #000000 !important;
                                background-color: #fafafa !important;
                                padding: 10px 20px !important;
                                border-right: 1px solid rgba(0, 0, 0, 0.125);
                                border-top: 1px solid rgba(0, 0, 0, 0.125);
                                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                            }
                            .nav-link:hover {
                                color: #ffffff !important;
                                background-color: #b4bec8 !important;
                            }
                            .nav-link.active {
                                color: #004b7d !important;
                                background-color: #ffffff !important;
                                border-bottom: 1px solid #ffffff;
                                cursor: auto;
                            }
                        }
                        .nav-item:first-child {
                            .nav-link {
                                border-left: 1px solid rgba(0, 0, 0, 0.125);
                            }
                        }
                    }
                }
            }
            canvas {
                max-height: 285px;
            }
        }
    }
}
/*
*  PGS module -------------------------------------------->
*/
app-rer-planning {
    app-add-edit {
        .mat-table {
            max-height: 90% !important;
        }
    }
}
/*
*  scattered or shared selectors -------------------------------------------->
*/
.tooltip {
    font-family: "Open Sans" !important;
}
#scontent {
    overflow-y: auto;
    overflow-x: hidden;

    height: calc(100% - 60px);

    ul.nav {
        border-bottom: 3px solid #274397;

        .nav-item a {
            color: #8c96a0;
            text-transform: uppercase;
        }

        li {
            // font-family: 'Montserrat';
            font-weight: bold;
            font-size: 13px;
            padding: 10px;
        }
        li.active {
            background-color: #274397;
        }

        li.active a {
            background-color: #274397 !important;
            color: #fff !important;
        }
    }
    .nav-tabs {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: -20px;
        background-color: #fff;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .mat-tab-label {
        height: 48px;
    }

    .tab-content {
        padding-top: 1rem !important;
    }

    mdb-select > div > div.single {
        .value {
            padding-bottom: 5px !important;
        }
        .placeholder {
            color: #757575 !important;
        }
    }
}
app-phone-number-input .mat-form-field-prefix {
    width: 115px !important;
}

ngx-material-timepicker-container {
    .timepicker-overlay {
        z-index: 1200 !important;
    }
}

.snackbar-warning {
    background-color: #ffb5bc !important;
    border: solid 1px #dc3545;
    color: #000;
}

:host .actionButtonLabel {
    color: blue;
}

.arrow-down {
    margin-left: 10px !important;
}

*:focus {
    outline: none !important;
    box-shadow: unset !important;
}
.mat-header-cell.mat-sort-header-sorted {
    color: black;
}
.report-list {
    .mat-header-cell,
    .mat-cell {
        align-items: left;
        justify-content: left;
    }
    .mat-header-cell {
        align-items: left;
        justify-content: left;
    }
    .mat-sort-header-button {
        text-align: left;
    }
    .mat-cell {
        padding-left: 16px !important;
    }
    .mat-column-device_id,
    .mat-column-device {
        max-width: 80px;
    }
    .mat-column-distance {
        max-width: 90px;
    }
    *[class^="mat-column-duration-"],
    *[class*=" mat-column-duration-"] {
        max-width: 130px;
    }
    .mat-column-actions {
        max-width: 65px;
        text-align: center;
    }
    .mat-cell.actions {
        justify-content: center !important;
    }
}
@media screen and (max-width: 768px) {
    .mat-paginator-container {
        font-size: 9px !important;
    }
    .button-component .inner {
        padding: 3px 3px;
    }
}
// routes
.cdk-overlay-container {
    z-index: 1040 !important;
}

//for allowing scroll when dialog opened and content overflows
.cdk-global-overlay-wrapper {
    overflow: auto;
    pointer-events: auto;
}

.route-calculator-tab {
    .mat-tab-body-content {
        transition: none !important;
        transform: none !important;
    }

    mat-tab-header {
        margin-bottom: 0px !important;

        .mat-tab-label-content {
            font-size: 12px !important;
        }

        div.mat-tab-label {
            padding: 0 !important;
            color: #8c96aa !important;
            opacity: 0.8;
        }

        div.mat-tab-label-active {
            padding: 0 !important;
            color: #274397 !important;
            font-weight: 500;
            opacity: 1;
        }
    }

    .tab-content {
        padding: 0;
    }
}

.password-state-icon {
    font-size: smaller;
    color: #8c96a0;
}

mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #ffffff;
    border: 2px solid #00c87d;
}
mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #00c87d;
}
mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
    background-color: #00c87d;
}
mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
    background-color: #ffffff;
    border: 2px solid #00c87d;
}
.mat-slide-toggle-bar {
    height: 20px!important;
}
.mat-slide-toggle-thumb-container {
    top: -1px!important;
}
/*
*  scattered or shared selectors -------------------------------------------->
*/
app-rer-settings {
    .rer-tooltip {
        z-index: 99999999999999 !important;
    }

    .rer-tooltip .tooltip-inner {
        background: #274397;
        font-size: 13px !important;
        max-width: 260px !important;
        text-align: left !important;
        border-radius: 5px !important;
        padding: 0.5rem 0.3rem 0.1rem 0.25rem !important;
        ul {
            padding-inline-start: 25px !important;
        }
    }
    .rer-tooltip.bs-tooltip-top .arrow::before {
        border-top-color: #274397 !important;
    }
    .rer-tooltip.bs-tooltip-right .arrow::before {
        border-right-color: #274397 !important;
    }
    .rer-tooltip.bs-tooltip-bottom .arrow::before {
        border-bottom-color: #274397 !important;
    }
    .rer-tooltip.bs-tooltip-left .arrow::before {
        border-left-color: #274397 !important;
    }
}

app-recurentpayment {
    mat-form-field {
        .mat-form-field-infix {
            .mat-form-field-label {
                background-color: #fff;
                width: fit-content!important;
            }
        }
    }
}