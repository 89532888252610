@font-face {
  font-family: 'cargotrack-nav';
  src:  url('cargotrack-nav.eot?t7a1sp');
  src:  url('cargotrack-nav.eot?t7a1sp#iefix') format('embedded-opentype'),
    url('cargotrack-nav.ttf?t7a1sp') format('truetype'),
    url('cargotrack-nav.woff?t7a1sp') format('woff'),
    url('cargotrack-nav.svg?t7a1sp#cargotrack-nav') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-nav-"], [class*=" icon-nav-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cargotrack-nav' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-nav-navicon01:before {
  content: "\e900";
}
.icon-nav-navicon02:before {
  content: "\e901";
}
.icon-nav-navicon03:before {
  content: "\e902";
}
.icon-nav-navicon04:before {
  content: "\e903";
}
.icon-nav-navicon05:before {
  content: "\e904";
}
.icon-nav-navicon06:before {
  content: "\e905";
}
.icon-nav-navicon07:before {
  content: "\e906";
}
.icon-nav-navicon08:before {
  content: "\e907";
}
.icon-nav-navicon09:before {
  content: "\e908";
}
.icon-nav-navicon10:before {
  content: "\e909";
}
.icon-nav-navicon11:before {
  content: "\e90a";
}
.icon-nav-navicon12:before {
  content: "\e90b";
}
.icon-nav-navicon13:before {
  content: "\e90c";
}
.icon-nav-navicon14:before {
  content: "\e90d";
}
.icon-nav-navicon15:before {
  content: "\e90e";
}
.icon-nav-navicon16:before {
  content: "\e90f";
}
.icon-nav-navicon17:before {
  content: "\e910";
}
.icon-nav-navicon18:before {
  content: "\e911";
}
.icon-nav-navicon19:before {
  content: "\e912";
}
.icon-nav-navicon20:before {
  content: "\e913";
}
.icon-nav-navicon21:before {
  content: "\e914";
}
.icon-nav-navicon22:before {
  content: "\e915";
}
.icon-nav-navicon23:before {
  content: "\e916";
}
.icon-nav-navicon24:before {
  content: "\e917";
}
.icon-nav-navicon25:before {
  content: "\e918";
}
.icon-nav-navicon26:before {
  content: "\e919";
}
.icon-nav-navicon27:before {
  content: "\e91a";
}
.icon-nav-navicon28:before {
  content: "\e91b";
}
.icon-nav-navicon29:before {
  content: "\e91c";
}
.icon-nav-navicon30:before {
  content: "\e91d";
}
.icon-nav-navicon31:before {
  content: "\e91e";
}
.icon-nav-navicon32:before {
  content: "\e91f";
}
.icon-nav-navicon33:before {
  content: "\e920";
}
.icon-nav-navicon34:before {
  content: "\e921";
}
.icon-nav-navicon35:before {
  content: "\e922";
}
.icon-nav-navicon36:before {
  content: "\e923";
}
.icon-nav-navicon37:before {
  content: "\e924";
}
.icon-nav-navicon38:before {
  content: "\e925";
}
.icon-nav-navicon39:before {
  content: "\e926";
}
.icon-nav-navicon40:before {
  content: "\e927";
}
.icon-nav-navicon41:before {
  content: "\e928";
}
.icon-nav-navicon42:before {
  content: "\e929";
}
.icon-nav-navicon43:before {
  content: "\e92a";
}
.icon-nav-navicon44:before {
  content: "\e92b";
}
.icon-nav-navicon45:before {
  content: "\e92c";
}
.icon-nav-navicon46:before {
  content: "\e92d";
}
.icon-nav-navicon47:before {
  content: "\e92e";
}
.icon-nav-navicon48:before {
  content: "\e92f";
}
.icon-nav-navicon49:before {
  content: "\e930";
}
.icon-nav-navicon50:before {
  content: "\e931";
}
.icon-nav-navicon51:before {
  content: "\e932";
}
.icon-nav-navicon52:before {
  content: "\e933";
}
.icon-nav-navicon53:before {
  content: "\e934";
}
.icon-nav-navicon54:before {
  content: "\e935";
}
.icon-nav-navicon55:before {
  content: "\e936";
}
.icon-nav-navicon56:before {
  content: "\e937";
}
