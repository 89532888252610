/*
 * Map Module
 * HistoryInfo on GoogleMap 
 * DirectionArrowMarkers, VehicleMarkers
 */

img[src='assets/images/map/history-arrow.png#0'], img[src='assets/images/map/vehicleMarker.png#0'], img[src='assets/images/map/vehicleIcon.png#0'], img[src='assets/images/map/vehicleEngineOnIcon.png#0'], img[src='assets/images/map/vehicleEngineOnMarker.png#0'] {
   -webkit-transform: rotate(0deg);
   -moz-transform : rotate(0deg);
   -ms-transform : rotate(0deg);
   transform : rotate(0deg);
}

img[src='assets/images/map/history-arrow.png#5.625'], img[src='assets/images/map/vehicleMarker.png#5.625'], img[src='assets/images/map/vehicleIcon.png#5.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#5.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#5.625'] {
   -webkit-transform: rotate(5.625deg);
   -moz-transform : rotate(5.625deg);
   -ms-transform : rotate(5.625deg);
   transform : rotate(5.625deg);
}

img[src='assets/images/map/history-arrow.png#11.25'], img[src='assets/images/map/vehicleMarker.png#11.25'], img[src='assets/images/map/vehicleIcon.png#11.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#11.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#11.25'] {
   -webkit-transform: rotate(11.25deg);
   -moz-transform : rotate(11.25deg);
   -ms-transform : rotate(11.25deg);
   transform : rotate(11.25deg);
}

img[src='assets/images/map/history-arrow.png#16.875'], img[src='assets/images/map/vehicleMarker.png#16.875'], img[src='assets/images/map/vehicleIcon.png#16.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#16.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#16.875'] {
   -webkit-transform: rotate(16.875deg);
   -moz-transform : rotate(16.875deg);
   -ms-transform : rotate(16.875deg);
   transform : rotate(16.875deg);
}

img[src='assets/images/map/history-arrow.png#22.5'], img[src='assets/images/map/vehicleMarker.png#22.5'], img[src='assets/images/map/vehicleIcon.png#22.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#22.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#22.5'] {
   -webkit-transform: rotate(22.5deg);
   -moz-transform : rotate(22.5deg);
   -ms-transform : rotate(22.5deg);
   transform : rotate(22.5deg);
}

img[src='assets/images/map/history-arrow.png#28.125'], img[src='assets/images/map/vehicleMarker.png#28.125'], img[src='assets/images/map/vehicleIcon.png#28.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#28.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#28.125'] {
   -webkit-transform: rotate(28.125deg);
   -moz-transform : rotate(28.125deg);
   -ms-transform : rotate(28.125deg);
   transform : rotate(28.125deg);
}

img[src='assets/images/map/history-arrow.png#33.75'], img[src='assets/images/map/vehicleMarker.png#33.75'], img[src='assets/images/map/vehicleIcon.png#33.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#33.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#33.75'] {
   -webkit-transform: rotate(33.75deg);
   -moz-transform : rotate(33.75deg);
   -ms-transform : rotate(33.75deg);
   transform : rotate(33.75deg);
}

img[src='assets/images/map/history-arrow.png#39.375'], img[src='assets/images/map/vehicleMarker.png#39.375'], img[src='assets/images/map/vehicleIcon.png#39.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#39.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#39.375'] {
   -webkit-transform: rotate(39.375deg);
   -moz-transform : rotate(39.375deg);
   -ms-transform : rotate(39.375deg);
   transform : rotate(39.375deg);
}

img[src='assets/images/map/history-arrow.png#45'], img[src='assets/images/map/vehicleMarker.png#45'], img[src='assets/images/map/vehicleIcon.png#45'], img[src='assets/images/map/vehicleEngineOnIcon.png#45'], img[src='assets/images/map/vehicleEngineOnMarker.png#45'] {
   -webkit-transform: rotate(45deg);
   -moz-transform : rotate(45deg);
   -ms-transform : rotate(45deg);
   transform : rotate(45deg);
}

img[src='assets/images/map/history-arrow.png#50.625'], img[src='assets/images/map/vehicleMarker.png#50.625'], img[src='assets/images/map/vehicleIcon.png#50.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#50.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#50.625'] {
   -webkit-transform: rotate(50.625deg);
   -moz-transform : rotate(50.625deg);
   -ms-transform : rotate(50.625deg);
   transform : rotate(50.625deg);
}

img[src='assets/images/map/history-arrow.png#56.25'], img[src='assets/images/map/vehicleMarker.png#56.25'], img[src='assets/images/map/vehicleIcon.png#56.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#56.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#56.25'] {
   -webkit-transform: rotate(56.25deg);
   -moz-transform : rotate(56.25deg);
   -ms-transform : rotate(56.25deg);
   transform : rotate(56.25deg);
}

img[src='assets/images/map/history-arrow.png#61.875'], img[src='assets/images/map/vehicleMarker.png#61.875'], img[src='assets/images/map/vehicleIcon.png#61.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#61.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#61.875'] {
   -webkit-transform: rotate(61.875deg);
   -moz-transform : rotate(61.875deg);
   -ms-transform : rotate(61.875deg);
   transform : rotate(61.875deg);
}

img[src='assets/images/map/history-arrow.png#67.5'], img[src='assets/images/map/vehicleMarker.png#67.5'], img[src='assets/images/map/vehicleIcon.png#67.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#67.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#67.5'] {
   -webkit-transform: rotate(67.5deg);
   -moz-transform : rotate(67.5deg);
   -ms-transform : rotate(67.5deg);
   transform : rotate(67.5deg);
}

img[src='assets/images/map/history-arrow.png#73.125'], img[src='assets/images/map/vehicleMarker.png#73.125'], img[src='assets/images/map/vehicleIcon.png#73.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#73.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#73.125'] {
   -webkit-transform: rotate(73.125deg);
   -moz-transform : rotate(73.125deg);
   -ms-transform : rotate(73.125deg);
   transform : rotate(73.125deg);
}

img[src='assets/images/map/history-arrow.png#78.75'], img[src='assets/images/map/vehicleMarker.png#78.75'], img[src='assets/images/map/vehicleIcon.png#78.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#78.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#78.75'] {
   -webkit-transform: rotate(78.75deg);
   -moz-transform : rotate(78.75deg);
   -ms-transform : rotate(78.75deg);
   transform : rotate(78.75deg);
}

img[src='assets/images/map/history-arrow.png#84.375'], img[src='assets/images/map/vehicleMarker.png#84.375'], img[src='assets/images/map/vehicleIcon.png#84.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#84.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#84.375'] {
   -webkit-transform: rotate(84.375deg);
   -moz-transform : rotate(84.375deg);
   -ms-transform : rotate(84.375deg);
   transform : rotate(84.375deg);
}

img[src='assets/images/map/history-arrow.png#90'], img[src='assets/images/map/vehicleMarker.png#90'], img[src='assets/images/map/vehicleIcon.png#90'], img[src='assets/images/map/vehicleEngineOnIcon.png#90'], img[src='assets/images/map/vehicleEngineOnMarker.png#90'] {
   -webkit-transform: rotate(90deg);
   -moz-transform : rotate(90deg);
   -ms-transform : rotate(90deg);
   transform : rotate(90deg);
}

img[src='assets/images/map/history-arrow.png#95.625'], img[src='assets/images/map/vehicleMarker.png#95.625'], img[src='assets/images/map/vehicleIcon.png#95.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#95.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#95.625'] {
   -webkit-transform: rotate(95.625deg);
   -moz-transform : rotate(95.625deg);
   -ms-transform : rotate(95.625deg);
   transform : rotate(95.625deg);
}

img[src='assets/images/map/history-arrow.png#101.25'], img[src='assets/images/map/vehicleMarker.png#101.25'], img[src='assets/images/map/vehicleIcon.png#101.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#101.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#101.25'] {
   -webkit-transform: rotate(101.25deg);
   -moz-transform : rotate(101.25deg);
   -ms-transform : rotate(101.25deg);
   transform : rotate(101.25deg);
}

img[src='assets/images/map/history-arrow.png#106.875'], img[src='assets/images/map/vehicleMarker.png#106.875'], img[src='assets/images/map/vehicleIcon.png#106.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#106.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#106.875'] {
   -webkit-transform: rotate(106.875deg);
   -moz-transform : rotate(106.875deg);
   -ms-transform : rotate(106.875deg);
   transform : rotate(106.875deg);
}

img[src='assets/images/map/history-arrow.png#112.5'], img[src='assets/images/map/vehicleMarker.png#112.5'], img[src='assets/images/map/vehicleIcon.png#112.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#112.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#112.5'] {
   -webkit-transform: rotate(112.5deg);
   -moz-transform : rotate(112.5deg);
   -ms-transform : rotate(112.5deg);
   transform : rotate(112.5deg);
}

img[src='assets/images/map/history-arrow.png#118.125'], img[src='assets/images/map/vehicleMarker.png#118.125'], img[src='assets/images/map/vehicleIcon.png#118.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#118.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#118.125'] {
   -webkit-transform: rotate(118.125deg);
   -moz-transform : rotate(118.125deg);
   -ms-transform : rotate(118.125deg);
   transform : rotate(118.125deg);
}

img[src='assets/images/map/history-arrow.png#123.75'], img[src='assets/images/map/vehicleMarker.png#123.75'], img[src='assets/images/map/vehicleIcon.png#123.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#123.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#123.75'] {
   -webkit-transform: rotate(123.75deg);
   -moz-transform : rotate(123.75deg);
   -ms-transform : rotate(123.75deg);
   transform : rotate(123.75deg);
}

img[src='assets/images/map/history-arrow.png#129.375'], img[src='assets/images/map/vehicleMarker.png#129.375'], img[src='assets/images/map/vehicleIcon.png#129.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#129.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#129.375'] {
   -webkit-transform: rotate(129.375deg);
   -moz-transform : rotate(129.375deg);
   -ms-transform : rotate(129.375deg);
   transform : rotate(129.375deg);
}

img[src='assets/images/map/history-arrow.png#135'], img[src='assets/images/map/vehicleMarker.png#135'], img[src='assets/images/map/vehicleIcon.png#135'], img[src='assets/images/map/vehicleEngineOnIcon.png#135'], img[src='assets/images/map/vehicleEngineOnMarker.png#135'] {
   -webkit-transform: rotate(135deg);
   -moz-transform : rotate(135deg);
   -ms-transform : rotate(135deg);
   transform : rotate(135deg);
}

img[src='assets/images/map/history-arrow.png#140.625'], img[src='assets/images/map/vehicleMarker.png#140.625'], img[src='assets/images/map/vehicleIcon.png#140.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#140.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#140.625'] {
   -webkit-transform: rotate(140.625deg);
   -moz-transform : rotate(140.625deg);
   -ms-transform : rotate(140.625deg);
   transform : rotate(140.625deg);
}

img[src='assets/images/map/history-arrow.png#146.25'], img[src='assets/images/map/vehicleMarker.png#146.25'], img[src='assets/images/map/vehicleIcon.png#146.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#146.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#146.25'] {
   -webkit-transform: rotate(146.25deg);
   -moz-transform : rotate(146.25deg);
   -ms-transform : rotate(146.25deg);
   transform : rotate(146.25deg);
}

img[src='assets/images/map/history-arrow.png#151.875'], img[src='assets/images/map/vehicleMarker.png#151.875'], img[src='assets/images/map/vehicleIcon.png#151.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#151.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#151.875'] {
   -webkit-transform: rotate(151.875deg);
   -moz-transform : rotate(151.875deg);
   -ms-transform : rotate(151.875deg);
   transform : rotate(151.875deg);
}

img[src='assets/images/map/history-arrow.png#157.5'], img[src='assets/images/map/vehicleMarker.png#157.5'], img[src='assets/images/map/vehicleIcon.png#157.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#157.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#157.5'] {
   -webkit-transform: rotate(157.5deg);
   -moz-transform : rotate(157.5deg);
   -ms-transform : rotate(157.5deg);
   transform : rotate(157.5deg);
}

img[src='assets/images/map/history-arrow.png#163.125'], img[src='assets/images/map/vehicleMarker.png#163.125'], img[src='assets/images/map/vehicleIcon.png#163.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#163.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#163.125'] {
   -webkit-transform: rotate(163.125deg);
   -moz-transform : rotate(163.125deg);
   -ms-transform : rotate(163.125deg);
   transform : rotate(163.125deg);
}

img[src='assets/images/map/history-arrow.png#168.75'], img[src='assets/images/map/vehicleMarker.png#168.75'], img[src='assets/images/map/vehicleIcon.png#168.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#168.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#168.75'] {
   -webkit-transform: rotate(168.75deg);
   -moz-transform : rotate(168.75deg);
   -ms-transform : rotate(168.75deg);
   transform : rotate(168.75deg);
}

img[src='assets/images/map/history-arrow.png#174.375'], img[src='assets/images/map/vehicleMarker.png#174.375'], img[src='assets/images/map/vehicleIcon.png#174.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#174.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#174.375'] {
   -webkit-transform: rotate(174.375deg);
   -moz-transform : rotate(174.375deg);
   -ms-transform : rotate(174.375deg);
   transform : rotate(174.375deg);
}

img[src='assets/images/map/history-arrow.png#180'], img[src='assets/images/map/vehicleMarker.png#180'], img[src='assets/images/map/vehicleIcon.png#180'], img[src='assets/images/map/vehicleEngineOnIcon.png#180'], img[src='assets/images/map/vehicleEngineOnMarker.png#180'] {
   -webkit-transform: rotate(180deg);
   -moz-transform : rotate(180deg);
   -ms-transform : rotate(180deg);
   transform : rotate(180deg);
}

img[src='assets/images/map/history-arrow.png#185.625'], img[src='assets/images/map/vehicleMarker.png#185.625'], img[src='assets/images/map/vehicleIcon.png#185.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#185.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#185.625'] {
   -webkit-transform: rotate(185.625deg);
   -moz-transform : rotate(185.625deg);
   -ms-transform : rotate(185.625deg);
   transform : rotate(185.625deg);
}

img[src='assets/images/map/history-arrow.png#191.25'], img[src='assets/images/map/vehicleMarker.png#191.25'], img[src='assets/images/map/vehicleIcon.png#191.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#191.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#191.25'] {
   -webkit-transform: rotate(191.25deg);
   -moz-transform : rotate(191.25deg);
   -ms-transform : rotate(191.25deg);
   transform : rotate(191.25deg);
}

img[src='assets/images/map/history-arrow.png#196.875'], img[src='assets/images/map/vehicleMarker.png#196.875'], img[src='assets/images/map/vehicleIcon.png#196.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#196.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#196.875'] {
   -webkit-transform: rotate(196.875deg);
   -moz-transform : rotate(196.875deg);
   -ms-transform : rotate(196.875deg);
   transform : rotate(196.875deg);
}

img[src='assets/images/map/history-arrow.png#202.5'], img[src='assets/images/map/vehicleMarker.png#202.5'], img[src='assets/images/map/vehicleIcon.png#202.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#202.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#202.5'] {
   -webkit-transform: rotate(202.5deg);
   -moz-transform : rotate(202.5deg);
   -ms-transform : rotate(202.5deg);
   transform : rotate(202.5deg);
}

img[src='assets/images/map/history-arrow.png#208.125'], img[src='assets/images/map/vehicleMarker.png#208.125'], img[src='assets/images/map/vehicleIcon.png#208.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#208.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#208.125'] {
   -webkit-transform: rotate(208.125deg);
   -moz-transform : rotate(208.125deg);
   -ms-transform : rotate(208.125deg);
   transform : rotate(208.125deg);
}

img[src='assets/images/map/history-arrow.png#213.75'], img[src='assets/images/map/vehicleMarker.png#213.75'], img[src='assets/images/map/vehicleIcon.png#213.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#213.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#213.75'] {
   -webkit-transform: rotate(213.75deg);
   -moz-transform : rotate(213.75deg);
   -ms-transform : rotate(213.75deg);
   transform : rotate(213.75deg);
}

img[src='assets/images/map/history-arrow.png#219.375'], img[src='assets/images/map/vehicleMarker.png#219.375'], img[src='assets/images/map/vehicleIcon.png#219.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#219.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#219.375'] {
   -webkit-transform: rotate(219.375deg);
   -moz-transform : rotate(219.375deg);
   -ms-transform : rotate(219.375deg);
   transform : rotate(219.375deg);
}

img[src='assets/images/map/history-arrow.png#225'], img[src='assets/images/map/vehicleMarker.png#225'], img[src='assets/images/map/vehicleIcon.png#225'], img[src='assets/images/map/vehicleEngineOnIcon.png#225'], img[src='assets/images/map/vehicleEngineOnMarker.png#225'] {
   -webkit-transform: rotate(225deg);
   -moz-transform : rotate(225deg);
   -ms-transform : rotate(225deg);
   transform : rotate(225deg);
}

img[src='assets/images/map/history-arrow.png#230.625'], img[src='assets/images/map/vehicleMarker.png#230.625'], img[src='assets/images/map/vehicleIcon.png#230.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#230.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#230.625'] {
   -webkit-transform: rotate(230.625deg);
   -moz-transform : rotate(230.625deg);
   -ms-transform : rotate(230.625deg);
   transform : rotate(230.625deg);
}

img[src='assets/images/map/history-arrow.png#236.25'], img[src='assets/images/map/vehicleMarker.png#236.25'], img[src='assets/images/map/vehicleIcon.png#236.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#236.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#236.25'] {
   -webkit-transform: rotate(236.25deg);
   -moz-transform : rotate(236.25deg);
   -ms-transform : rotate(236.25deg);
   transform : rotate(236.25deg);
}

img[src='assets/images/map/history-arrow.png#241.875'], img[src='assets/images/map/vehicleMarker.png#241.875'], img[src='assets/images/map/vehicleIcon.png#241.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#241.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#241.875'] {
   -webkit-transform: rotate(241.875deg);
   -moz-transform : rotate(241.875deg);
   -ms-transform : rotate(241.875deg);
   transform : rotate(241.875deg);
}

img[src='assets/images/map/history-arrow.png#247.5'], img[src='assets/images/map/vehicleMarker.png#247.5'], img[src='assets/images/map/vehicleIcon.png#247.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#247.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#247.5'] {
   -webkit-transform: rotate(247.5deg);
   -moz-transform : rotate(247.5deg);
   -ms-transform : rotate(247.5deg);
   transform : rotate(247.5deg);
}

img[src='assets/images/map/history-arrow.png#253.125'], img[src='assets/images/map/vehicleMarker.png#253.125'], img[src='assets/images/map/vehicleIcon.png#253.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#253.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#253.125'] {
   -webkit-transform: rotate(253.125deg);
   -moz-transform : rotate(253.125deg);
   -ms-transform : rotate(253.125deg);
   transform : rotate(253.125deg);
}

img[src='assets/images/map/history-arrow.png#258.75'], img[src='assets/images/map/vehicleMarker.png#258.75'], img[src='assets/images/map/vehicleIcon.png#258.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#258.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#258.75'] {
   -webkit-transform: rotate(258.75deg);
   -moz-transform : rotate(258.75deg);
   -ms-transform : rotate(258.75deg);
   transform : rotate(258.75deg);
}

img[src='assets/images/map/history-arrow.png#264.375'], img[src='assets/images/map/vehicleMarker.png#264.375'], img[src='assets/images/map/vehicleIcon.png#264.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#264.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#264.375'] {
   -webkit-transform: rotate(264.375deg);
   -moz-transform : rotate(264.375deg);
   -ms-transform : rotate(264.375deg);
   transform : rotate(264.375deg);
}

img[src='assets/images/map/history-arrow.png#270'], img[src='assets/images/map/vehicleMarker.png#270'], img[src='assets/images/map/vehicleIcon.png#270'], img[src='assets/images/map/vehicleEngineOnIcon.png#270'], img[src='assets/images/map/vehicleEngineOnMarker.png#270'] {
   -webkit-transform: rotate(270deg);
   -moz-transform : rotate(270deg);
   -ms-transform : rotate(270deg);
   transform : rotate(270deg);
}

img[src='assets/images/map/history-arrow.png#275.625'], img[src='assets/images/map/vehicleMarker.png#275.625'], img[src='assets/images/map/vehicleIcon.png#275.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#275.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#275.625'] {
   -webkit-transform: rotate(275.625deg);
   -moz-transform : rotate(275.625deg);
   -ms-transform : rotate(275.625deg);
   transform : rotate(275.625deg);
}

img[src='assets/images/map/history-arrow.png#281.25'], img[src='assets/images/map/vehicleMarker.png#281.25'], img[src='assets/images/map/vehicleIcon.png#281.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#281.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#281.25'] {
   -webkit-transform: rotate(281.25deg);
   -moz-transform : rotate(281.25deg);
   -ms-transform : rotate(281.25deg);
   transform : rotate(281.25deg);
}

img[src='assets/images/map/history-arrow.png#286.875'], img[src='assets/images/map/vehicleMarker.png#286.875'], img[src='assets/images/map/vehicleIcon.png#286.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#286.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#286.875'] {
   -webkit-transform: rotate(286.875deg);
   -moz-transform : rotate(286.875deg);
   -ms-transform : rotate(286.875deg);
   transform : rotate(286.875deg);
}

img[src='assets/images/map/history-arrow.png#292.5'], img[src='assets/images/map/vehicleMarker.png#292.5'], img[src='assets/images/map/vehicleIcon.png#292.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#292.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#292.5'] {
   -webkit-transform: rotate(292.5deg);
   -moz-transform : rotate(292.5deg);
   -ms-transform : rotate(292.5deg);
   transform : rotate(292.5deg);
}

img[src='assets/images/map/history-arrow.png#298.125'], img[src='assets/images/map/vehicleMarker.png#298.125'], img[src='assets/images/map/vehicleIcon.png#298.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#298.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#298.125'] {
   -webkit-transform: rotate(298.125deg);
   -moz-transform : rotate(298.125deg);
   -ms-transform : rotate(298.125deg);
   transform : rotate(298.125deg);
}

img[src='assets/images/map/history-arrow.png#303.75'], img[src='assets/images/map/vehicleMarker.png#303.75'], img[src='assets/images/map/vehicleIcon.png#303.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#303.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#303.75'] {
   -webkit-transform: rotate(303.75deg);
   -moz-transform : rotate(303.75deg);
   -ms-transform : rotate(303.75deg);
   transform : rotate(303.75deg);
}

img[src='assets/images/map/history-arrow.png#309.375'], img[src='assets/images/map/vehicleMarker.png#309.375'], img[src='assets/images/map/vehicleIcon.png#309.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#309.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#309.375'] {
   -webkit-transform: rotate(309.375deg);
   -moz-transform : rotate(309.375deg);
   -ms-transform : rotate(309.375deg);
   transform : rotate(309.375deg);
}

img[src='assets/images/map/history-arrow.png#315'], img[src='assets/images/map/vehicleMarker.png#315'], img[src='assets/images/map/vehicleIcon.png#315'], img[src='assets/images/map/vehicleEngineOnIcon.png#315'], img[src='assets/images/map/vehicleEngineOnMarker.png#315'] {
   -webkit-transform: rotate(315deg);
   -moz-transform : rotate(315deg);
   -ms-transform : rotate(315deg);
   transform : rotate(315deg);
}

img[src='assets/images/map/history-arrow.png#320.625'], img[src='assets/images/map/vehicleMarker.png#320.625'], img[src='assets/images/map/vehicleIcon.png#320.625'], img[src='assets/images/map/vehicleEngineOnIcon.png#320.625'], img[src='assets/images/map/vehicleEngineOnMarker.png#320.625'] {
   -webkit-transform: rotate(320.625deg);
   -moz-transform : rotate(320.625deg);
   -ms-transform : rotate(320.625deg);
   transform : rotate(320.625deg);
}

img[src='assets/images/map/history-arrow.png#326.25'], img[src='assets/images/map/vehicleMarker.png#326.25'], img[src='assets/images/map/vehicleIcon.png#326.25'], img[src='assets/images/map/vehicleEngineOnIcon.png#326.25'], img[src='assets/images/map/vehicleEngineOnMarker.png#326.25'] {
   -webkit-transform: rotate(326.25deg);
   -moz-transform : rotate(326.25deg);
   -ms-transform : rotate(326.25deg);
   transform : rotate(326.25deg);
}

img[src='assets/images/map/history-arrow.png#331.875'], img[src='assets/images/map/vehicleMarker.png#331.875'], img[src='assets/images/map/vehicleIcon.png#331.875'], img[src='assets/images/map/vehicleEngineOnIcon.png#331.875'], img[src='assets/images/map/vehicleEngineOnMarker.png#331.875'] {
   -webkit-transform: rotate(331.875deg);
   -moz-transform : rotate(331.875deg);
   -ms-transform : rotate(331.875deg);
   transform : rotate(331.875deg);
}

img[src='assets/images/map/history-arrow.png#337.5'], img[src='assets/images/map/vehicleMarker.png#337.5'], img[src='assets/images/map/vehicleIcon.png#337.5'], img[src='assets/images/map/vehicleEngineOnIcon.png#337.5'], img[src='assets/images/map/vehicleEngineOnMarker.png#337.5'] {
   -webkit-transform: rotate(337.5deg);
   -moz-transform : rotate(337.5deg);
   -ms-transform : rotate(337.5deg);
   transform : rotate(337.5deg);
}

img[src='assets/images/map/history-arrow.png#343.125'], img[src='assets/images/map/vehicleMarker.png#343.125'], img[src='assets/images/map/vehicleIcon.png#343.125'], img[src='assets/images/map/vehicleEngineOnIcon.png#343.125'], img[src='assets/images/map/vehicleEngineOnMarker.png#343.125'] {
   -webkit-transform: rotate(343.125deg);
   -moz-transform : rotate(343.125deg);
   -ms-transform : rotate(343.125deg);
   transform : rotate(343.125deg);
}

img[src='assets/images/map/history-arrow.png#348.75'], img[src='assets/images/map/vehicleMarker.png#348.75'], img[src='assets/images/map/vehicleIcon.png#348.75'], img[src='assets/images/map/vehicleEngineOnIcon.png#348.75'], img[src='assets/images/map/vehicleEngineOnMarker.png#348.75'] {
   -webkit-transform: rotate(348.75deg);
   -moz-transform : rotate(348.75deg);
   -ms-transform : rotate(348.75deg);
   transform : rotate(348.75deg);
}

img[src='assets/images/map/history-arrow.png#354.375'], img[src='assets/images/map/vehicleMarker.png#354.375'], img[src='assets/images/map/vehicleIcon.png#354.375'], img[src='assets/images/map/vehicleEngineOnIcon.png#354.375'], img[src='assets/images/map/vehicleEngineOnMarker.png#354.375'] {
   -webkit-transform: rotate(354.375deg);
   -moz-transform : rotate(354.375deg);
   -ms-transform : rotate(354.375deg);
   transform : rotate(354.375deg);
}

img[src='assets/images/map/history-arrow.png#360'], img[src='assets/images/map/vehicleMarker.png#360'], img[src='assets/images/map/vehicleIcon.png#360'], img[src='assets/images/map/vehicleEngineOnIcon.png#360'], img[src='assets/images/map/vehicleEngineOnMarker.png#360'] {
   -webkit-transform: rotate(0deg);
   -moz-transform : rotate(0deg);
   -ms-transform : rotate(0deg);
   transform : rotate(0deg);
}