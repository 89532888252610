@font-face {
    font-family: 'ctsicon-general-20210805';
    src: url('assets/fonts/ctsicon-general-20210805.eot');
    src: url('assets/fonts/ctsicon-general-20210805.eot#iefix') format('embedded-opentype'),
        url('assets/fonts/ctsicon-general-20210805.ttf') format('truetype'),
        url('assets/fonts/ctsicon-general-20210805.woff') format('woff'),
        url('assets/fonts/ctsicon-general-20210805.svg#ctsicon-general-20210805Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="ctsicon-"], [class*=" ctsicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ctsicon-general-20210805' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* TOPNAV */
.ctsicon-topnav-3trucks-off:before {
    content: "\e100";
}
.ctsicon-topnav-3trucks-on:before {
    content: "\e101";
}
.ctsicon-topnav-2trucks-off:before {
    content: "\e102";
}
.ctsicon-topnav-2trucks-on:before {
    content: "\e103";
}
.ctsicon-topnav-1truck-off:before {
    content: "\e104";
}
.ctsicon-topnav-1truck-on:before {
    content: "\e105";
}

.ctsicon-topnav-reports-off:before {
    content: "\e10C";
}
.ctsicon-topnav-reports-on:before {
    content: "\e10D";
}
.ctsicon-topnav-fap-off:before {
    content: "\e10E";
}
.ctsicon-topnav-fap-on:before {
    content: "\e10F";
}
.ctsicon-topnav-rer-off:before {
    content: "\e110";
}
.ctsicon-topnav-rer-on:before {
    content: "\e111";
}
.ctsicon-topnav-pgs-off:before {
    content: "\e112";
}
.ctsicon-topnav-pgs-on:before {
    content: "\e113";
}
.ctsicon-topnav-contacts-off:before {
    content: "\e114";
}
.ctsicon-topnav-contacts-on:before {
    content: "\e115";
}

.ctsicon-topnav-modules-off:before {
    content: "\e124";
}
.ctsicon-topnav-modules-on:before {
    content: "\e125";
}
.ctsicon-topnav-settings-off:before {
    content: "\e126";
}
.ctsicon-topnav-settings-on:before {
    content: "\e127";
}
.ctsicon-topnav-notifications-off:before {
    content: "\e128";
}
.ctsicon-topnav-notifications-on:before {
    content: "\e129";
}
.ctsicon-topnav-2persons-off:before {
    content: "\e12A";
}
.ctsicon-topnav-2persons-on:before {
    content: "\e12B";
}
.ctsicon-topnav-1person-off:before {
    content: "\e12C";
}
.ctsicon-topnav-1person-on:before {
    content: "\e12D";
}
.ctsicon-topnav-1person-wheel-off:before {
    content: "\e12E";
}
.ctsicon-topnav-1person-wheel-on:before {
    content: "\e12F";
}

.ctsicon-topnav-logout-off:before {
    content: "\e13C";
}
.ctsicon-topnav-logout-on:before {
    content: "\e13D";
}
.ctsicon-topnav-message-off:before {
    content: "\e13E";
}
.ctsicon-topnav-message-on:before {
    content: "\e13F";
}
.ctsicon-topnav-documents-folder-off:before {
    content: "\e140";
}
.ctsicon-topnav-documents-folder-on:before {
    content: "\e141";
}
.ctsicon-topnav-road-off:before {
    content: "\e142";
}
.ctsicon-topnav-road-on:before {
    content: "\e143";
}
.ctsicon-topnav-finance-off:before {
    content: "\e144";
}
.ctsicon-topnav-finance-on:before {
    content: "\e145";
}

/* --------------------------------------------------------------------- */

.ctsicon-tabnav-box:before {
    content: "\e500";
}

.ctsicon-tabnav-waypoint:before {
    content: "\e501";
}
.ctsicon-tabnav-costs:before {
    content: "\e502";
}

.ctsicon-tabnav-users:before {
    content: "\e503";
}

.ctsicon-tabnav-receip:before {
    content: "\e504";
}

.ctsicon-tabnav-invoice-dollar:before {
    content: "\e505";
}

.ctsicon-tabnav-company:before {
    content: "\e506";
}

.ctsicon-tabnav-gear:before {
    content: "\e507";
}

.ctsicon-tabnav-destination-point:before {
    content: "\e508";
}

.ctsicon-tabnav-sms-box:before {
    content: "\e509";
}

.ctsicon-tabnav-goods-carry:before {
    content: "\e50a";
}

.ctsicon-tabnav-incident:before {
    content: "\e50B";
}

/* ---------------------------------------------------------- */

.ctsicon-leftnav-dashboard:before {
    content: "\e400";
}

.ctsicon-leftnav-goods-carry-big:before {
    content: "\e401";
}

.ctsicon-leftnav-truck:before {
    content: "\e402";
}

.ctsicon-leftnav-user-data:before {
    content: "\e403";
}

.ctsicon-leftnav-big-gear:before {
    content: "\e404";
}

.ctsicon-leftnav-dollar-big-sign:before {
    content: "\e405";
}

.ctsicon-leftnav-2xcarry-goods:before {
    content: "\e406";
}

.ctsicon-leftnav-rapoarte:before {
    content: "\e407";
}
.ctsicon-leftnav-rapoarte-plin:before {
    content: "\e408";
}

.ctsicon-leftnav-incident:before {
    content: "\e409";
}

/* ---------------------------------------------------------- */

.ctsicon-buttons-plus-sign:before {
    content: "\e300";
}

.ctsicon-buttons-info-point:before {
    content: "\e301";
}

.ctsicon-buttons-close-box:before {
    content: "\e302";
}

.ctsicon-buttons-close-box-small:before {
    content: "\e303";
}

.ctsicon-buttons-trash-container:before {
    content: "\e304";
}

.ctsicon-buttons-edit-button:before {
    content: "\e305";
}

.ctsicon-buttons-download:before {
    content: "\e306";
}

.ctsicon-buttons-move-left:before {
    content: "\e307";
}

.ctsicon-buttons-move-right:before {
    content: "\e308";
}
.ctsicon-buttons-read-only-edit:before {
    content: "\e309";
}

/* ---------------------------------------------------------- */

.ctsicon-general-calendar:before {
    content: "\e000";
}

.ctsicon-general-orientation-sign:before {
    content: "\e001";
}

.ctsicon-general-upgrade:before {
    content: "\e002";
}

.ctsicon-general-road:before {
    content: "\e003";
}

.ctsicon-general-timer:before {
    content: "\e004";
}

.ctsicon-general-trip-cost:before {
    content: "\e005";
}

.ctsicon-general-delivery:before {
    content: "\e006";
}

.ctsicon-general-upload-file:before {
    content: "\e007";
}

.ctsicon-general-pdf-doc:before {
    content: "\e008";
}

.ctsicon-general-customer-invoice:before {
    content: "\e009";
}

.ctsicon-general-upload-files:before {
    content: "\e00a";
}

.ctsicon-general-invoice-number1:before {
    content: "\e00b";
}

.ctsicon-general-invoice-number2:before {
    content: "\e00c";
}

.ctsicon-general-invoice-number3:before {
    content: "\e00d";
}

.ctsicon-general-actiuni:before {
    content: "\e00e";
}

.ctsicon-general-modificari:before {
    content: "\e00f";
}

.ctsicon-general-history:before {
    content: "\e010";
}

/* ---------------------------------------------------------- */

/* FAP */
.ctsicon-fap-rfid:before {
    content: "\e200";
}
.ctsicon-fap-waste-bin:before {
    content: "\e201";
}

/* ---------------------------------------------------------- */

/* PGS */
.ctsicon-pgs-star-on:before {
    content: "\e600";
}
.ctsicon-pgs-star-off:before {
    content: "\e601";
}
