@font-face {
    font-family: 'cmicon-map-20210205';
    src: url('assets/fonts/modules/map/cmicon-map-20210205.eot');
    src: url('assets/fonts/modules/map/cmicon-map-20210205.eot#iefix') format('embedded-opentype'),
        url('assets/fonts/modules/map/cmicon-map-20210205.ttf') format('truetype'),
        url('assets/fonts/modules/map/cmicon-map-20210205.woff') format('woff'),
        url('assets/fonts/modules/map/cmicon-map-20210205.svg#cmicon-map-20210205Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="cmicon-map-"], [class*=" cmicon-map-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cmicon-map-20210205' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cmicon-map-di-tachometer:before {
    content: "\e000";
}
.cmicon-map-di-road:before {
    content: "\e001";
}
.cmicon-map-di-fuel-canister:before {
    content: "\e003";
}
.cmicon-map-di-power-supply:before {
    content: "\e004";
}
.cmicon-map-di-fuel-drops:before {
    content: "\e005";
}
.cmicon-map-di-temperature:before {
    content: "\e006";
}
.cmicon-map-di-temperature1:before {
    content: "\e007";
}
.cmicon-map-di-temperature2:before {
    content: "\e008";
}
.cmicon-map-di-temperature3:before {
    content: "\e009";
}
.cmicon-map-di-temperature4:before {
    content: "\e00a";
}
.cmicon-map-di-rfid:before {
    content: "\e00c";
}
.cmicon-map-di-rfid1:before {
    content: "\e00d";
}
.cmicon-map-di-rfid2:before {
    content: "\e00e";
}
.cmicon-map-di-door-highlight:before {
    content: "\e012";
}
.cmicon-map-di-door:before {
    content: "\e013";
}
.cmicon-map-di-door1:before {
    content: "\e014";
}
.cmicon-map-di-door2:before {
    content: "\e015";
}
.cmicon-map-di-baterry100:before {
    content: "\e018";
}
.cmicon-map-di-baterry75:before {
    content: "\e019";
}
.cmicon-map-di-baterry50:before {
    content: "\e01a";
}
.cmicon-map-di-baterry25:before {
    content: "\e01b";
}
.cmicon-map-di-baterry0:before {
    content: "\e01c";
}
.cmicon-map-di-auxiliary:before {
    content: "\e01e";
}
.cmicon-map-di-auxiliary1:before {
    content: "\e01f";
}
.cmicon-map-di-auxiliary2:before {
    content: "\e020";
}
.cmicon-map-di-status:before {
    content: "\e024";
}
.cmicon-map-di-status-driving:before {
    content: "\e025";
}
.cmicon-map-di-status-neutral:before {
    content: "\e026";
}
.cmicon-map-di-status-parked:before {
    content: "\e027";
}
