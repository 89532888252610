@font-face {
    font-family: 'cmicon-roadtax-20210120';
    src: url('assets/fonts/modules/roadtax/cmicon-roadtax-20210120.eot');
    src: url('assets/fonts/modules/roadtax/cmicon-roadtax-20210120.eot#iefix') format('embedded-opentype'),
        url('assets/fonts/modules/roadtax/cmicon-roadtax-20210120.ttf') format('truetype'),
        url('assets/fonts/modules/roadtax/cmicon-roadtax-20210120.woff') format('woff'),
        url('assets/fonts/modules/roadtax/cmicon-roadtax-20210120.svg#cmicon-roadtax-20210120Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="cmicon-roadtax-"], [class*=" cmicon-roadtax-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cmicon-roadtax-20210120' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cmicon-roadtax-rovinieta-cat-a:before {
    content: "\e000";
}
.cmicon-roadtax-rovinieta-cat-b:before {
    content: "\e001";
}
.cmicon-roadtax-rovinieta-cat-c:before {
    content: "\e002";
}
.cmicon-roadtax-rovinieta-cat-d:before {
    content: "\e003";
}
.cmicon-roadtax-rovinieta-cat-e:before {
    content: "\e004";
}
.cmicon-roadtax-rovinieta-cat-f:before {
    content: "\e005";
}
.cmicon-roadtax-rovinieta-cat-g:before {
    content: "\e006";
}
.cmicon-roadtax-rovinieta-cat-h:before {
    content: "\e007";
}
