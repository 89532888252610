@font-face {
  font-family: 'cts-webfont-20240627';
  src:  url('assets/fonts/cts-webfont-20240627.eot?8uaft9');
  src:  url('assets/fonts/cts-webfont-20240627.eot?8uaft9#iefix') format('embedded-opentype'),
    url('assets/fonts/cts-webfont-20240627.ttf?8uaft9') format('truetype'),
    url('assets/fonts/cts-webfont-20240627.woff?8uaft9') format('woff'),
    url('assets/fonts/cts-webfont-20240627.svg?8uaft9#cts-webfont-20240627') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cts-webfont-20240627' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tms-dash-axle:before {
  content: "\e900";
}
.icon-tms-dash-battery:before {
  content: "\e901";
}
.icon-tms-dash-consumption:before {
  content: "\e902";
}
.icon-tms-dash-cruise-control:before {
  content: "\e903";
}
.icon-tms-dash-fuel1:before {
  content: "\e904";
}
.icon-tms-dash-fuel2:before {
  content: "\e905";
}
.icon-tms-dash-odometer:before {
  content: "\e906";
}
.icon-tms-dash-oil:before {
  content: "\e907";
}
.icon-tms-dash-temp:before {
  content: "\e908";
}
.icon-tmss-action-edit:before {
  content: "\e909";
}
.icon-tmss-action-expand:before {
  content: "\e90a";
}
.icon-tmss-action-filter:before {
  content: "\e90b";
}
.icon-tmss-action-view-cost:before {
  content: "\e90c";
}
.icon-tmss-action-view-distance:before {
  content: "\e90d";
}
.icon-tmss-action-view-time:before {
  content: "\e90e";
}
.icon-tmss-icon-app:before {
  content: "\e90f";
}
.icon-tmss-icon-email:before {
  content: "\e910";
}
.icon-tmss-icon-message:before {
  content: "\e911";
}
.icon-tmss-list-action-delete:before {
  content: "\e912";
}
.icon-tmss-list-action-download:before {
  content: "\e913";
}
.icon-tmss-list-action-edit:before {
  content: "\e914";
}
.icon-tmss-list-status01:before {
  content: "\e915";
}
.icon-tmss-list-status02:before {
  content: "\e916";
}
.icon-tmss-list-status03:before {
  content: "\e917";
}
.icon-tmss-menu-settings:before {
  content: "\e918";
}
.icon-tmss-tab-accounts:before {
  content: "\e919";
}
.icon-tmss-tab-application:before {
  content: "\e91a";
}
.icon-tmss-tab-cargo3d:before {
  content: "\e91b";
}
.icon-tmss-tab-close:before {
  content: "\e91c";
}
.icon-tmss-tab-comm-log:before {
  content: "\e91d";
}
.icon-tmss-tab-company:before {
  content: "\e91e";
}
.icon-tmss-tab-contacts:before {
  content: "\e91f";
}
.icon-tmss-tab-contracts:before {
  content: "\e920";
}
.icon-tmss-tab-dashboard:before {
  content: "\e921";
}
.icon-tmss-tab-documents:before {
  content: "\e922";
}
.icon-tmss-tab-documents-folder:before {
  content: "\e923";
}
.icon-tmss-tab-expenses-invoices:before {
  content: "\e924";
}
.icon-tmss-tab-intermediate:before {
  content: "\e925";
}
.icon-tmss-tab-partners:before {
  content: "\e926";
}
.icon-tmss-tab-route:before {
  content: "\e927";
}
.icon-tmss-tab-shimpents-overview:before {
  content: "\e928";
}
.icon-tmss-tab-shipments:before {
  content: "\e929";
}
.icon-tmss-tab-vehicles:before {
  content: "\e92a";
}
.icon-tmss-vehicle-box-truck:before {
  content: "\e92b";
}
.icon-tmss-vehicle-coach:before {
  content: "\e92c";
}
.icon-tmss-vehicle-flatbed-truck:before {
  content: "\e92d";
}
.icon-tmss-vehicle-tow-truck:before {
  content: "\e92e";
}
.icon-tmss-vehicle-van:before {
  content: "\e92f";
}
.icon-cts-selection-checkbox-on-alt:before {
  content: "\e930";
}
.icon-cts-action-add:before {
  content: "\e931";
}
.icon-cts-vehicle-distance-large:before {
  content: "\e932";
}
.icon-cts-vehicle-fuel-large:before {
  content: "\e933";
}
.icon-cts-action-close:before {
  content: "\e934";
}
.icon-cts-action-close-wbg:before {
  content: "\e935";
}
.icon-cts-action-coordonates:before {
  content: "\e936";
}
.icon-cts-action-del:before {
  content: "\e937";
}
.icon-cts-action-delete:before {
  content: "\e938";
}
.icon-cts-action-edit:before {
  content: "\e939";
}
.icon-cts-action-expand:before {
  content: "\e93a";
}
.icon-cts-action-move:before {
  content: "\e93b";
}
.icon-cts-action-new-document:before {
  content: "\e93c";
}
.icon-cts-action-new-folder:before {
  content: "\e93d";
}
.icon-cts-action-open:before {
  content: "\e93e";
}
.icon-cts-action-pdf:before {
  content: "\e93f";
}
.icon-cts-action-print:before {
  content: "\e940";
}
.icon-cts-action-refresh:before {
  content: "\e941";
}
.icon-cts-action-search:before {
  content: "\e942";
}
.icon-cts-action-xls:before {
  content: "\e943";
}
.icon-cts-button-add-document:before {
  content: "\e944";
}
.icon-cts-chat-keyboard:before {
  content: "\e945";
}
.icon-cts-chat-send:before {
  content: "\e946";
}
.icon-cts-chat-send-wbg:before {
  content: "\e947";
}
.icon-cts-chevron-left:before {
  content: "\e948";
}
.icon-cts-chevron-right:before {
  content: "\e949";
}
.icon-tmsl-widget-48hours:before {
  content: "\e94a";
}
.icon-tmsl-widget-idle:before {
  content: "\e94b";
}
.icon-tmsl-widget-scheduled:before {
  content: "\e94c";
}
.icon-tmsl-widget-shipping:before {
  content: "\e94d";
}
.icon-tmsl-widget-unavailable:before {
  content: "\e94e";
}
.icon-tmsl-widget-warning:before {
  content: "\e94f";
}
.icon-tmsl-widget-working:before {
  content: "\e950";
}
.icon-ctl-folder-empty:before {
  content: "\e951";
}
.icon-ctl-folder-populated:before {
  content: "\e952";
}
.icon-ctl-nav-binding-off:before {
  content: "\e953";
}
.icon-ctl-nav-binding-on:before {
  content: "\e954";
}
.icon-ctl-nav-dashboard-off:before {
  content: "\e955";
}
.icon-ctl-nav-dashboard-on:before {
  content: "\e956";
}
.icon-ctl-nav-documents-off:before {
  content: "\e957";
}
.icon-ctl-nav-documents-on:before {
  content: "\e958";
}
.icon-ctl-nav-drivers-off:before {
  content: "\e959";
}
.icon-ctl-nav-drivers-on:before {
  content: "\e95a";
}
.icon-ctl-nav-finance-off:before {
  content: "\e95b";
}
.icon-ctl-nav-finance-on:before {
  content: "\e95c";
}
.icon-ctl-nav-fleet-off:before {
  content: "\e95d";
}
.icon-ctl-nav-fleet-on:before {
  content: "\e95e";
}
.icon-ctl-nav-highway-off:before {
  content: "\e95f";
}
.icon-ctl-nav-highway-on:before {
  content: "\e960";
}
.icon-ctl-nav-imessage-off:before {
  content: "\e961";
}
.icon-ctl-nav-imessage-on:before {
  content: "\e962";
}
.icon-ctl-nav-linkshare-off:before {
  content: "\e963";
}
.icon-ctl-nav-linkshare-on:before {
  content: "\e964";
}
.icon-ctl-nav-logout:before {
  content: "\e965";
}
.icon-ctl-nav-more-off:before {
  content: "\e966";
}
.icon-ctl-nav-more-on:before {
  content: "\e967";
}
.icon-ctl-nav-notifications:before {
  content: "\e968";
}
.icon-ctl-nav-reports-off:before {
  content: "\e969";
}
.icon-ctl-nav-reports-on:before {
  content: "\e96a";
}
.icon-ctl-nav-route-creator-off:before {
  content: "\e96b";
}
.icon-ctl-nav-route-creator-on:before {
  content: "\e96c";
}
.icon-ctl-nav-routes-off:before {
  content: "\e96d";
}
.icon-ctl-nav-routes-on:before {
  content: "\e96e";
}
.icon-ctl-nav-settings:before {
  content: "\e96f";
}
.icon-ctl-nav-tacho-off:before {
  content: "\e970";
}
.icon-cts-direction-arrow:before {
  content: "\e971";
}
.icon-cts-direction-lines:before {
  content: "\e972";
}
.icon-cts-direction-pause:before {
  content: "\e973";
}
.icon-cts-direction-stop:before {
  content: "\e974";
}
.icon-cts-empty-chat:before {
  content: "\e975";
}
.icon-cts-empty-drivers:before {
  content: "\e976";
}
.icon-cts-empty-reports:before {
  content: "\e977";
}
.icon-cts-empty-routes:before {
  content: "\e978";
}
.icon-cts-empty-vehicles:before {
  content: "\e979";
}
.icon-cts-file-doc:before {
  content: "\e97a";
}
.icon-cts-file-jpg:before {
  content: "\e97b";
}
.icon-cts-file-other:before {
  content: "\e97c";
}
.icon-cts-file-pdf:before {
  content: "\e97d";
}
.icon-cts-file-png:before {
  content: "\e97e";
}
.icon-cts-file-xls:before {
  content: "\e97f";
}
.icon-cts-filter-closed:before {
  content: "\e980";
}
.icon-ctl-nav-tacho-on:before {
  content: "\e981";
}
.icon-ctl-nav-track1-off:before {
  content: "\e982";
}
.icon-ctl-nav-track1-on:before {
  content: "\e983";
}
.icon-ctl-nav-track2-off:before {
  content: "\e984";
}
.icon-ctl-nav-track2-on:before {
  content: "\e985";
}
.icon-ctl-nav-track3-off:before {
  content: "\e986";
}
.icon-ctl-nav-track3-on:before {
  content: "\e987";
}
.icon-ctl-user-no-photo:before {
  content: "\e988";
}
.icon-ctl-vehicle-direction:before {
  content: "\e989";
}
.icon-ctl-vehicle-distance:before {
  content: "\e98a";
}
.icon-ctl-vehicle-fuel:before {
  content: "\e98b";
}
.icon-ctl-vehicle-speed:before {
  content: "\e98c";
}
.icon-ctl-vehicle-time:before {
  content: "\e98d";
}
.icon-cts-filter-closed-wbg:before {
  content: "\e98e";
}
.icon-cts-filter-open:before {
  content: "\e98f";
}
.icon-cts-filter-open-wbg:before {
  content: "\e990";
}
.icon-cts-information:before {
  content: "\e991";
}
.icon-cts-location-pin:before {
  content: "\e992";
}
.icon-cts-location-pin-empty:before {
  content: "\e993";
}
.icon-cts-location-pin-finish:before {
  content: "\e994";
}
.icon-cts-location-pin-start:before {
  content: "\e995";
}
.icon-cts-map-fforward:before {
  content: "\e996";
}
.icon-cts-map-pause:before {
  content: "\e997";
}
.icon-cts-map-play:before {
  content: "\e998";
}
.icon-cts-map-zoom-in:before {
  content: "\e999";
}
.icon-cts-map-zoom-out:before {
  content: "\e99a";
}
.icon-cts-notification-driver:before {
  content: "\e99b";
}
.icon-cts-notification-message:before {
  content: "\e99c";
}
.icon-cts-notification-report:before {
  content: "\e99d";
}
.icon-cts-notification-route:before {
  content: "\e99e";
}
.icon-cts-notifications:before {
  content: "\e99f";
}
.icon-cts-password-forgot:before {
  content: "\e9a0";
}
.icon-cts-route-direction:before {
  content: "\e9a1";
}
.icon-cts-route-finish:before {
  content: "\e9a2";
}
.icon-cts-route-start:before {
  content: "\e9a3";
}
.icon-cts-route-warning:before {
  content: "\e9a4";
}
.icon-cts-selection-checkbox-off:before {
  content: "\e9a5";
}
.icon-cts-selection-checkbox-on:before {
  content: "\e9a6";
}
.icon-cts-selection-radio-off:before {
  content: "\e9a7";
}
.icon-cts-selection-radio-on:before {
  content: "\e9a8";
}
.icon-cts-sidebar-toggle:before {
  content: "\e9a9";
}
.icon-cts-sidebar-toggle-wbg:before {
  content: "\e9aa";
}
.icon-cts-vehicle-distance:before {
  content: "\e9ab";
}
.icon-cts-vehicle-duration:before {
  content: "\e9ac";
}
.icon-cts-vehicle-fuel:before {
  content: "\e9ad";
}
.icon-cts-vehicle-speed:before {
  content: "\e9ae";
}
.icon-tmss-menu-dashboard:before {
  content: "\e9af";
}
.icon-tmss-menu-drivers:before {
  content: "\e9b0";
}
.icon-tmss-menu-finances:before {
  content: "\e9b1";
}
.icon-tmss-menu-shippings:before {
  content: "\e9b2";
}
.icon-tmss-menu-vehicles:before {
  content: "\e9b3";
}
.icon-tmss-notifications-all:before {
  content: "\e9b4";
}
.icon-tmss-notifications-documents:before {
  content: "\e9b5";
}
.icon-tmss-notifications-finances:before {
  content: "\e9b6";
}
.icon-tmss-notifications-shippings:before {
  content: "\e9b7";
}
.icon-tmss-table-header-48hours:before {
  content: "\e9b8";
}
.icon-tmss-table-header-progress:before {
  content: "\e9b9";
}
.icon-tmss-table-header-scheduled:before {
  content: "\e9ba";
}
.icon-tmss-table-header-warning:before {
  content: "\e9bb";
}
.icon-tmss-widget-delete:before {
  content: "\e9bc";
}
.icon-tmss-widget-drag:before {
  content: "\e9bd";
}
.icon-tmss-widget-filter:before {
  content: "\e9be";
}
.icon-cts-action-copy:before {
  content: "\e9bf";
}
.icon-cts-action-map:before {
  content: "\e9c0";
}
.icon-cts-action-new-route:before {
  content: "\e9c1";
}
.icon-cts-map-focus:before {
  content: "\e9c2";
}
.icon-cts-map-fullscreen:before {
  content: "\e9c3";
}
.icon-cts-map-hu-go:before {
  content: "\e9c4";
}
.icon-cts-map-streetview:before {
  content: "\e9c5";
}
.icon-cts-map-traffic:before {
  content: "\e9c6";
}
.icon-cts-vehicle-consumption-large:before {
  content: "\e9c7";
}
.icon-cts-vehicle-consumption:before {
  content: "\e9c8";
}
.icon-cts-vehicle-battery:before {
  content: "\e9c9";
}
.icon-cts-vehicle-battery-large:before {
  content: "\e9ca";
}
.icon-cts-notification-new:before {
  content: "\e9cb";
}
.icon-cts-sidenav-controls:before {
  content: "\e9cc";
}
.icon-cts-sidenav-drivers:before {
  content: "\e9cd";
}
.icon-cts-sidenav-history:before {
  content: "\e9ce";
}
.icon-cts-sidenav-route:before {
  content: "\e9cf";
}
.icon-cts-sidenav-vehicle:before {
  content: "\e9d0";
}
.icon-cts-action-enlarge:before {
  content: "\e9d1";
}
.icon-cts-action-export:before {
  content: "\e9d2";
}
.icon-cts-action-graph-toggle:before {
  content: "\e9d3";
}
.icon-cts-action-next:before {
  content: "\e9d4";
}
.icon-cts-action-prev:before {
  content: "\e9d5";
}
.icon-cts-action-route:before {
  content: "\e9d6";
}
.icon-cts-action-settings:before {
  content: "\e9d7";
}
.icon-cts-button-alarm:before {
  content: "\e9d8";
}
.icon-cts-button-camera:before {
  content: "\e9d9";
}
.icon-cts-button-lock:before {
  content: "\e9da";
}
.icon-cts-button-save:before {
  content: "\e9db";
}
.icon-cts-button-unlock:before {
  content: "\e9dc";
}
.icon-cts-calendar:before {
  content: "\e9dd";
}
.icon-cts-log-stop:before {
  content: "\e9de";
}
.icon-ctl-nav-modules-off:before {
  content: "\e9df";
}
.icon-ctl-nav-modules-on:before {
  content: "\e9e0";
}
.icon-cts-action-collapse:before {
  content: "\e9e1";
}
.icon-tablepdf:before {
  content: "\e9e2";
}
.icon-downXLS:before {
  content: "\e9e3";
}
.icon-screen:before {
  content: "\e9e4";
}
.icon-group:before {
  content: "\e9e5";
}
.icon-Vector:before {
  content: "\e9e6";
}
.icon-geofence:before {
  content: "\e9e7";
}
.icon-cts-user-events:before {
  content: "\e9e8";
}
.icon-cts-user-devices:before {
  content: "\e9e9";
}
.icon-cts-user-union:before {
  content: "\e9ea";
}
.icon-cts-user-activity:before {
  content: "\e9eb";
}
.icon-cts-user-overview:before {
  content: "\e9ec";
}
.icon-cts-search-remove:before {
  content: "\e9ed";
}
.icon-cts-route-pickup:before {
  content: "\e9ee";
}
.icon-cts-route-delivery-pickup:before {
  content: "\e9ef";
}
.icon-cts-notification-financiar:before {
  content: "\e9f0";
}
.icon-cts-notification-financial:before {
  content: "\e9f1";
}
.icon-cts-notification-system:before {
  content: "\e9f2";
}
.icon-cts-notification-events:before {
  content: "\e9f3";
}
.icon-cts-notification-marketing:before {
  content: "\e9f4";
}
.icon-cts-notification-reports:before {
  content: "\e9f5";
}
.icon-cts-notification-icon:before {
  content: "\e9f6";
}
.icon-ctl-nav-notifications-on:before {
  content: "\e9f7";
}
.icon-ctl-nav-settings-on:before {
  content: "\e9f8";
}
.icon-ctl-nav-drivers-wheel-off:before {
  content: "\e9f9";
}
.icon-ctl-nav-drivers-wheel-on:before {
  content: "\e9fa";
}
.icon-notdef:before {
  content: "\e9fb";
}
.icon-notdef1:before {
  content: "\e9fc";
}
.icon-e-transport-documents:before {
  content: "\e100";
}
.icon-e-transport-vehicle:before {
  content: "\e101";
  color: #3b5297;
}
.icon-e-transport-calendar:before {
  content: "\e102";
}
.icon-e-transport-route:before {
  content: "\e103"; 
  color: #3b5297;
}
.icon-e-transport-beneficiary:before {
  content: "\e104";
  color: #3b5297;
}
.icon-e-transport-tab:before {
  content: "\e105";
  font-size: 30px;
  line-height: 30px;
}
.icon-e-transport-on:before {
  content: "\e105";
}
.icon-e-transport-off:before {
  content: "\e105";
}